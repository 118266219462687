<template>
    <div class="codics-factory-admin-map-body">
        <div class="codics-factory-admin-map-control">
            <el-upload class="upload-demo" action="cdn.bosscaigou.com" accept="image/svg+xml" :http-request="uploadFile"
                :limit="1" :show-file-list="false">
                <el-button class="iconfont codics-factory-admin-map-control-search" type="primary" plain
                    style="margin-right: 0 !important;">&#xe639;
                    点击上传</el-button>
            </el-upload>

        </div>
        <div class="codics-factory-admin-map-table-content">
            <div class="codics-factory-admin-home-sjtj3" data-content="冷库地图"
                style="padding-bottom: 0 !important;width: calc(50% - 20px);margin-right: 20px;">
                <div id="eChartF" class="codics-factory-admin-home-echarts" style="padding-bottom: 0 !important;"></div>
                <svg v-show="mapState" class="icon codics-factory-admin-map-default" aria-hidden="true" title="工厂缺省">
                    <use xlink:href="#icon-wangluoyichang" title="工厂缺省"></use>
                </svg>
            </div>
            <div class="codics-factory-admin-map-table-view">
                <el-table stripe class="codics-factory-admin-map-table"
                    :data="searchTxt == '' ? deviceList : searchData" border style="width: 100%"
                    @selection-change="handleSelectionChange" ref="userTable">
                    <el-table-column prop="id" label="主键" width="70">
                    </el-table-column>
                    <el-table-column prop="pcode" label="设备码" width="180" style="user-select: none;">
                    </el-table-column>
                    <el-table-column prop="imei" label="物联网编号" width="180">
                    </el-table-column>
                    <el-table-column prop="x" label="X坐标" width="80">
                    </el-table-column>
                    <el-table-column prop="y" label="Y坐标" width="80">
                    </el-table-column>
                    <el-table-column align="right" label="操作" min-width="150">
                        <template slot-scope="scope">
                            <el-input v-show="scope.row.showState" v-model="nowPos.x"
                                style="width: 80px;margin-right: 15px;" placeholder="请输入内容" size="mini"></el-input>
                            <el-input v-show="scope.row.showState" v-model="nowPos.y"
                                style="width: 80px;margin-right: 15px;" placeholder="请输入内容" size="mini"></el-input>
                            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                                style="margin-right: 10px;">{{ scope.row.showState ? '保存' : '设置' }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="codics-factory-admin-map-table-page" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import svgData from '../../../../public/factory/huayuan.svg';
import { listDevice, bindDevices, searchUsers, bindThod, deleteDevices, bindHumth, mapInfo, getQiNiuToken, addMap, setPos } from '@/api';
import { randomString } from '@/utils/myutils';
import * as qiniu from 'qiniu-js';
export default {
    data() {
        return {
            bindVisible: false,
            thodVisible: false,
            humVisible: false,
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            qiNiuInfo: {
                token: null
            },
            formLabelWidth: '80px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            deviceList: [],
            selectData: [],
            usersOptions: [],
            userLoading: false,
            selectUserData: '',
            thodNum: 0,
            humNum: 0,
            nowSearchTxt: '',
            mapState: true,
            nowPos: {
                x: 0,
                y: 0
            }
        }
    },
    mounted() {

        // this.listDeviceFuc();

        this.mapInfoFuc();

        this.getTokenFuc();

        this.watchSize('eChartF', (width, height) => {
            this.myChart2 && this.myChart2.resize();
        });


    },
    methods: {
        async addMapFuc(map) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addMap({ map: map });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        initChart2(data) {

            let _data = data;

            console.log(data)

            let t = data.record;

            data = [];

            for (let idx in t) {

                data.push({
                    name: t[idx].pcode,
                    value: t[idx].temp
                });

            };

            let geoCoordMap = {};

            for (let idx in t) {

                geoCoordMap[t[idx].pcode] = [t[idx].x, t[idx].y];

            };

            const _this = this;
            const takenSeatNames = ['22号冷库'];
            const chartDom = document.getElementById("eChartF")
            this.myChart2 = echarts.init(chartDom)
            fetch('https://cdn.bosscaigou.com/' + _data.data.dmap).then(response => response.text()).then(svgText => {
                echarts.registerMap('factory', { svg: svgText });
                // var data = [
                //     { name: '1号设备', value: 0 },
                //     { name: '2号设备', value: -2 },
                //     { name: '3号设备', value: 1 },
                //     { name: '4号设备', value: 2 },
                //     { name: '5号设备', value: -5 }
                // ];
                // var geoCoordMap = {
                //     '1号设备': [30, 5],
                //     '2号设备': [380, 205],
                //     '3号设备': [940, 5],
                //     '4号设备': [340, 450],
                //     '5号设备': [650, 450]
                // }
                console.log(data, geoCoordMap)
                var convertData = function (data) {
                    var res = [];
                    for (var i = 0; i < data.length; i++) {
                        var geoCoord = geoCoordMap[data[i].name];
                        if (geoCoord) {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value)
                            });
                        }
                    }
                    return res;
                };
                var option = {
                    geo: {
                        map: 'factory',
                        roam: true,
                        selectedMode: 'multiple',
                        layoutCenter: ['50%', '50%'],
                        layoutSize: '80%',
                        itemStyle: {
                            color: '#fff'
                        },
                        emphasis: {
                            itemStyle: {
                                color: undefined,
                                borderColor: 'green',
                                borderWidth: 2
                            },
                            label: {
                                show: true
                            }
                        },
                        select: {
                            itemStyle: {
                                color: 'green'
                            },
                            label: {
                                show: true,
                                textBorderColor: '#fff',
                                textBorderWidth: 2
                            }
                        },
                        regions: _this.makeTakenRegions(takenSeatNames)
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: '设备温度(℃)',
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            data: convertData(
                                data.sort(function (a, b) {
                                    return b.value - a.value;
                                }).slice(0, 6)
                            ),
                            encode: {
                                value: 2
                            },
                            symbolSize: 9,
                            showEffectOn: 'emphasis',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                formatter: '{b}',
                                position: 'right',
                                show: true
                            },
                            itemStyle: {
                                color: '#333',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            zlevel: 1
                        },
                        // {
                        //     name: '冷库状态（℃）',
                        //     type: 'map',
                        //     map: 'factory',
                        //     layoutCenter: ['50%', '50%'],
                        //     layoutSize: '150%',
                        //     roam: true,
                        //     emphasis: {
                        //         label: {
                        //             show: false
                        //         }
                        //     },
                        //     selectedMode: false,
                        //     data: [
                        //         { name: '1号冷库', value: 0 },
                        //         { name: '2号冷库', value: -2 }
                        //     ]
                        // },
                        // {
                        //     name: '冷库状态（℃）',
                        //     type: 'map',
                        //     map: 'factory',
                        //     layoutCenter: ['50%', '50%'],
                        //     layoutSize: '150%',
                        //     // roam: true,
                        //     // emphasis: {
                        //     //     label: {
                        //     //         show: false
                        //     //     }
                        //     // },
                        //     // selectedMode: false,
                        //     data: [
                        //         { name: '1号设备', value: 0 },
                        //         { name: '3号冷库', value: -2 }
                        //     ]
                        // }
                    ]
                };
                this.myChart2.on('geoselectchanged', function (params) {
                    const selectedNames = params.allSelected[0].name.slice();
                    // Remove taken seats.
                    for (var i = selectedNames.length - 1; i >= 0; i--) {
                        if (takenSeatNames.indexOf(selectedNames[i]) >= 0) {
                            selectedNames.splice(i, 1);
                        }
                    }
                    // console.log('selected', selectedNames);
                });
                option && this.myChart2.setOption(option);
                this.myChart2.getZr().on('click', function (params) {

                    var pointInPixel = [params.offsetX, params.offsetY];
                    var pointInGrid = _this.myChart2.convertFromPixel('geo', pointInPixel);
                    _this.nowPos.x = parseInt(pointInGrid[0]);
                    _this.nowPos.y = parseInt(pointInGrid[1]);
        
                });

            });

        },
        makeTakenRegions(takenSeatNames) {
            var regions = [];
            for (var i = 0; i < takenSeatNames.length; i++) {
                regions.push({
                    name: takenSeatNames[i],
                    silent: true,
                    itemStyle: {
                        color: '#FFF'
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#333',
                            borderWidth: 1
                        },
                        label: {
                            show: true
                        }
                    },
                    select: {
                        itemStyle: {
                            color: '#333'
                        },
                        label: {
                            show: true
                        }
                    }
                });
            }
            return regions;
        },
        async mapInfoFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await mapInfo();

            loading.close();

            if (data.code == 200) {

                if (data.data) {

                    this.initChart2(data);

                    this.mapState = false;



                }

                this.deviceList = data.record;

            } else {

                this.$message.error(data.message);

            }

        },
        watchSize(id, fuc) {

            const observedElement = document.getElementById(id);
            // 创建一个ResizeObserver实例并定义回调函数
            const resizeObserver = new ResizeObserver(entries => {

                for (let entry of entries) {

                    const width = entry.contentRect.width;

                    const height = entry.contentRect.height;

                    fuc(width, height);

                }

            });
            // 开始观察元素
            resizeObserver.observe(observedElement);

        },
        handleBindClick() {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.bindVisible = true;

        },
        async deleteDevicesFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteDevices({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleThodClick(c) {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.thodVisible = true;

        },
        icodeFilter(v, r, c) {

            console.log(v, r.icode)

            return r.icode === v;

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        async bindDevicesFuc(sid, ids) {

            if (sid == null || sid == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的商户数据！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindDevices({ sid: sid, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindThodFuc(thod, ids) {

            if (thod == null || thod == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindThod({ thod: thod, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindHumthFuc(humth, ids) {

            if (humth == null || humth == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的湿度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindHumth({ humth: humth, ids: ids });

            this.humVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleUserTagClose(tag) {
            this.$refs.userTable.selection.splice(this.$refs.userTable.selection.indexOf(tag), 1);
            this.selectData = this.$refs.userTable.selection;
            if (this.selectData.length == 0) {
                this.bindVisible = false;
                this.thodVisible = false;
            }
        },
        async listDeviceFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listDevice({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.nowSearchTxt == '') {

                    this.deviceList = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
            } else {
                this.searchSize = val;
            }
            this.listDeviceFuc();
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
            } else {
                this.searchPage = val;
            }
            this.listDeviceFuc();
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        uploadFile(uploadInfo) {

            let imgPath = 'huayuan/map/' + randomString(10) + '_' + new Date().getTime() + '_' + this.userInfo?.user?.id + '.svg';

            const _that = this;
            // 上传文件
            const observer2 = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {

                    _that.addMapFuc(res.key);

                }
            }

            const observable2 = qiniu.upload(uploadInfo.file, imgPath, this.qiNiuInfo.token, {}, {
            })

            const subscription2 = observable2.subscribe(observer2) // 上传开始
        },
        async handleEdit(idx, row) {

            if (row.showState) {

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                let data = await setPos({ id: row.id, x: this.nowPos.x, y: this.nowPos.y });

                loading.close();

                if (data.code == 200) {

                    this.$message({
                        message: '数据绑定成功！',
                        type: 'success'
                    });

                    setTimeout(() => {

                        window.location.reload();

                    }, 300);

                } else {

                    this.$message.error(data.message);

                }

            } else {

                this.searchTxt = 1;

                for (let item in this.deviceList) {

                    this.deviceList[item].showState = false;

                }

                this.deviceList[idx].showState = true;

                this.deviceList = [...this.deviceList];

                setTimeout(() => {

                    this.searchTxt = '';

                }, 1);

            }

        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-map-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-factory-admin-map-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-map-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-map-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-map-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-map-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-factory-admin-map-signal {
    height: 16px !important;
    width: 16px !important;
}

.codics-factory-admin-map-table-content {
    height: calc(100% - 100px);
    width: 100%;
    position: relative;
    float: left;
}

.codics-factory-admin-map-sjtj3 {
    height: 100%;
    width: 50%;
    position: relative;
    float: left;
}

.codics-factory-admin-map-table-view {
    height: 100%;
    width: 50%;
    position: relative;
    float: left;
}

.codics-factory-admin-map-default {
    height: 130px;
    width: 130px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-factory-admin-map-upload {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
}
</style>