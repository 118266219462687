<template>
    <div class="codics-factory-admin-home-body">
        <div class="codics-factory-admin-home-card-box">
            <div class="codics-factory-admin-home-card-item">
                <div class="codics-factory-admin-home-card">

                </div>
            </div>
            <div class="codics-factory-admin-home-card-item">
                <div class="codics-factory-admin-home-card">

                </div>
            </div>
            <div class="codics-factory-admin-home-card-item">
                <div class="codics-factory-admin-home-card">

                </div>
            </div>
            <div class="codics-factory-admin-home-card-item">
                <div class="codics-factory-admin-home-card">

                </div>
            </div>
            <div class="codics-factory-admin-home-card-item" style="width: 24% !important;">
                <div class="codics-factory-admin-home-card">

                </div>
            </div>
        </div>
        <div class="codics-factory-admin-home-card-left">
            <div class="codics-factory-admin-home-echarts-box">
                <div class="codics-factory-admin-home-echarts-card">
                    <div id="eChart" class="codics-factory-admin-home-echarts"></div>
                    <div class="codics-factory-admin-home-echarts-card-header">
                        <div class="codics-factory-admin-home-echarts-card-header-quan"></div>第1号冷藏库
                        <div class="codics-factory-admin-home-echarts-card-header-more iconfont">更多数据 &#xeb1b;</div>
                    </div>
                </div>
            </div>
            <div class="codics-factory-admin-home-echarts-box">
                <div class="codics-factory-admin-home-echarts-card"
                    style="padding-bottom: 0 !important;overflow: hidden;">
                    <div id="eChart2" class="codics-factory-admin-home-echarts">
                    </div>
                    <div class="codics-factory-admin-home-echarts-card-header">
                        <div class="codics-factory-admin-home-echarts-card-header-quan"></div>冷库温度地图
                        <div class="codics-factory-admin-home-echarts-card-header-more iconfont">更多数据 &#xeb1b;</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import svgData from '../../../../public/factory/svg.svg';
import { listRecords } from '@/api';
export default {
    data() {
        return {
            myChart: null,
            myChart2: null,
            deviceImei: ['0863011068101453', '0863011068105025']
        }
    },
    mounted() {
        // this.initChart();
        this.listRecordsFuc();
        this.initChart2();
        this.watchSize('eChart', (width, height) => {
            this.myChart && this.myChart.resize();
        });
        this.watchSize('eChart2', (width, height) => {
            this.myChart && this.myChart2.resize();
        });

    },
    methods: {
        initChart(data) {

            let length = data.data.length;

            let nowDate = Date.now();

            let t = Array.from({ length }, (_, i) => nowDate - i * 1800000);

            let n = new Date().getDay();

            t = t.map((n) => {

                let date = new Date(n);

                let r = n == date.getDay() ? '' : '昨日 ';

                let hour = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());

                let menute = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());

                let second = date.getSeconds() >= 10 ? date.getSeconds() : ('0' + date.getSeconds());

                return `${hour}:${menute}:${second}`;

            });

            t[0] = '现在';

            let d = data.data.map(function (item, index) {
                return item?.temp; // 返回值可以用于创建新数组
            });

            let d2 = data.list1.map(function (item, index) {
                return item?.hum; // 返回值可以用于创建新数组
            });

            console.log(d, t)

            var chartDom = document.getElementById('eChart');

            this.myChart = echarts.init(chartDom);

            var option;

            option = {
                title: {
                    text: '',
                    show: false
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    y: 'bottom', // 位于左边
                    left: 'center'
                },
                toolbox: {
                    show: true,
                    top: 6,
                    right: 96,
                    itemSize: 11.5,
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: t.reverse()
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: t
                    }
                ],
                //     {
                //     type: 'category',
                //         boundaryGap: false,
                //             data: t.reverse()
                // }
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        name: '温度',
                        boundaryGap: [0.2, 0.2],
                        axisLabel: {
                            formatter: '{value} °C'
                        }
                    },
                    {
                        type: 'value',
                        scale: true,
                        name: '湿度',
                        formatter: '{value} %RH',
                        boundaryGap: [0.2, 0.2]
                    }
                ],
                // {
                //     type: 'value',
                //     axisLabel: {
                //         formatter: '{value} °C'
                //     }
                // },
                grid: {
                    top: 80
                },
                series: [
                    {
                        name: '仓库温度',
                        type: 'line',
                        smooth: true,
                        data: d,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ],
                            // itemStyle: {
                            //     normal: {
                            //         borderColor: 'red',
                            //         borderWidth: 2
                            //     },
                            //     emphasis: {
                            //         borderColor: 'blue',
                            //         borderWidth: 3
                            //     }
                            // }
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        },
                        lineStyle: {
                            normal: {
                                color: function (params) {
                                    if (params.value > 28) {
                                        return '#FF0000'; // 超过特定值后的线条颜色
                                    } else {
                                        return '#5c7bd9'; // 默认颜色
                                    }
                                },
                            },
                            emphasis: {
                                color: function (params) {
                                    if (params.value > 28) {
                                        return '#FF0000'; // 超过特定值后的线条颜色
                                    } else {
                                        return '#5c7bd9'; // 默认颜色
                                    }
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    // 自定义判断逻辑，如果数据大于特定值则改变颜色
                                    if (params.value > 28) {
                                        return '#FF0000'; // 超过特定值后的点颜色
                                    } else {
                                        return '#5c7bd9'; // 默认颜色
                                    }
                                }
                            }
                        }
                        // },
                        // lineStyle: {
                        //     normal: {
                        //         color: function (params) {
                        //             if (params.value > 28) {
                        //                 return '#FF0000'; // 超过特定值后的线条颜色
                        //             } else {
                        //                 return '#5c7bd9'; // 默认颜色
                        //             }
                        //         }
                        //     }
                        // }
                    },
                    {
                        name: '仓库湿度',
                        type: 'bar',
                        smooth: true,
                        data: d2,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        markPoint: {
                            data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                        },
                        markLine: {
                            data: [
                                { type: 'average', name: 'Avg' },
                                [
                                    {
                                        symbol: 'none',
                                        x: '90%',
                                        yAxis: 'max'
                                    },
                                    {
                                        symbol: 'circle',
                                        label: {
                                            position: 'start',
                                            formatter: 'Max'
                                        },
                                        type: 'max',
                                        name: '最高点'
                                    }
                                ]
                            ]
                        }
                    }
                ]
            };

            option && this.myChart.setOption(option);

        },
        initChart2() {
            const _this = this;
            const takenSeatNames = ['22号冷库'];
            const chartDom = document.getElementById("eChart2")
            this.myChart2 = echarts.init(chartDom)
            fetch(svgData).then(response => response.text()).then(svgText => {
                echarts.registerMap('factory', { svg: svgText });
                var data = [
                    { name: '1号设备', value: 0 },
                    { name: '2号设备', value: -2 },
                    { name: '3号设备', value: 1 },
                    { name: '4号设备', value: 2 },
                    { name: '5号设备', value: -5 }
                ];
                var geoCoordMap = {
                    '1号设备': [30, 5],
                    '2号设备': [380, 205],
                    '3号设备': [940, 5],
                    '4号设备': [340, 450],
                    '5号设备': [650, 450]
                }
                var convertData = function (data) {
                    var res = [];
                    for (var i = 0; i < data.length; i++) {
                        var geoCoord = geoCoordMap[data[i].name];
                        if (geoCoord) {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value)
                            });
                        }
                    }
                    console.log('打印测试', res)
                    return res;
                };
                var option = {
                    geo: {
                        map: 'factory',
                        roam: true,
                        selectedMode: 'multiple',
                        layoutCenter: ['50%', '50%'],
                        layoutSize: '150%',
                        itemStyle: {
                            color: '#fff'
                        },
                        emphasis: {
                            itemStyle: {
                                color: undefined,
                                borderColor: 'green',
                                borderWidth: 2
                            },
                            label: {
                                show: true
                            }
                        },
                        select: {
                            itemStyle: {
                                color: 'green'
                            },
                            label: {
                                show: true,
                                textBorderColor: '#fff',
                                textBorderWidth: 2
                            }
                        },
                        regions: _this.makeTakenRegions(takenSeatNames)
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: '设备温度(℃)',
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            data: convertData(
                                data.sort(function (a, b) {
                                    return b.value - a.value;
                                }).slice(0, 6)
                            ),
                            encode: {
                                value: 2
                            },
                            symbolSize: 12,
                            showEffectOn: 'emphasis',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                formatter: '{b}',
                                position: 'right',
                                show: true
                            },
                            itemStyle: {
                                color: '#333',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            zlevel: 1
                        },
                        // {
                        //     name: '冷库状态（℃）',
                        //     type: 'map',
                        //     map: 'factory',
                        //     layoutCenter: ['50%', '50%'],
                        //     layoutSize: '150%',
                        //     roam: true,
                        //     emphasis: {
                        //         label: {
                        //             show: false
                        //         }
                        //     },
                        //     selectedMode: false,
                        //     data: [
                        //         { name: '1号冷库', value: 0 },
                        //         { name: '2号冷库', value: -2 }
                        //     ]
                        // },
                        // {
                        //     name: '冷库状态（℃）',
                        //     type: 'map',
                        //     map: 'factory',
                        //     layoutCenter: ['50%', '50%'],
                        //     layoutSize: '150%',
                        //     // roam: true,
                        //     // emphasis: {
                        //     //     label: {
                        //     //         show: false
                        //     //     }
                        //     // },
                        //     // selectedMode: false,
                        //     data: [
                        //         { name: '1号设备', value: 0 },
                        //         { name: '3号冷库', value: -2 }
                        //     ]
                        // }
                    ]
                };
                this.myChart2.on('geoselectchanged', function (params) {
                    const selectedNames = params.allSelected[0].name.slice();
                    // Remove taken seats.
                    for (var i = selectedNames.length - 1; i >= 0; i--) {
                        if (takenSeatNames.indexOf(selectedNames[i]) >= 0) {
                            selectedNames.splice(i, 1);
                        }
                    }
                    // console.log('selected', selectedNames);
                });
                option && this.myChart2.setOption(option);
            });

        },
        makeTakenRegions(takenSeatNames) {
            var regions = [];
            for (var i = 0; i < takenSeatNames.length; i++) {
                regions.push({
                    name: takenSeatNames[i],
                    silent: true,
                    itemStyle: {
                        color: '#FFF'
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#333',
                            borderWidth: 1
                        },
                        label: {
                            show: true
                        }
                    },
                    select: {
                        itemStyle: {
                            color: '#333'
                        },
                        label: {
                            show: true
                        }
                    }
                });
            }
            console.log('regions', regions)
            return regions;
        },
        watchSize(id, fuc) {

            const observedElement = document.getElementById(id);
            // 创建一个ResizeObserver实例并定义回调函数
            const resizeObserver = new ResizeObserver(entries => {

                for (let entry of entries) {

                    const width = entry.contentRect.width;

                    const height = entry.contentRect.height;

                    fuc(width, height);

                }

            });
            // 开始观察元素
            resizeObserver.observe(observedElement);

        },
        async listRecordsFuc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listRecords({ imei: this.deviceImei[0], imei2: this.deviceImei[1], icode: 22, pge: 1, psz: 10 });

            loading.close();

            if (data.code == 200) {

                this.initChart(data)

            } else {

                this.$message.error(data.message);

            }
        }
    }
}
</script>

<style>
.codics-factory-admin-home-body {
    height: 100%;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    overflow-x: hidden !important;
    padding-bottom: 15px;
}

.codics-factory-admin-home-card-box {
    height: 180px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-factory-admin-home-card-item {
    height: 100%;
    width: 19%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-factory-admin-home-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    background: linear-gradient(0deg, #f3f3f3, #e7f4ff);
    box-shadow: -2px -2px 6px #FFF, 2px 2px 6px #BABECC;
    border: 1px solid #EEE;
    border-radius: 8px;
    transition: .2s;
    cursor: pointer;
}

.codics-factory-admin-home-card-item:nth-child(2) .codics-factory-admin-home-card {
    background: linear-gradient(0deg, #f3f3f3, #ffe6e6) !important;
}

.codics-factory-admin-home-card-item:nth-child(3) .codics-factory-admin-home-card {
    background: linear-gradient(0deg, #f3f3f3, #f6ebff) !important;
}

.codics-factory-admin-home-card-item:nth-child(4) .codics-factory-admin-home-card {
    background: linear-gradient(0deg, #f3f3f3, #fff4dc) !important;
}

.codics-factory-admin-home-card-item:nth-child(5) .codics-factory-admin-home-card {
    background: linear-gradient(0deg, #f3f3f3, #D9FBEE) !important;
}

.codics-factory-admin-home-card:hover {
    box-shadow: -1px -1px 3px #FFF, 1px 1px 3px #BABECC;
}

.codics-factory-admin-home-card:active {
    box-shadow: 1px 1px 3px #FFF, -1px -1px 3px #BABECC;
}

.codics-factory-admin-home-card-left {
    height: fit-content;
    width: 65%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding-left: 7.5px;
}

.codics-factory-admin-home-echarts-box {
    height: 460px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 7.5px;
}

.codics-factory-admin-home-echarts-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-shadow: -2px -2px 6px #FFF, 2px 2px 6px #BABECC;
    border-radius: 8px;
    border: 1px solid #EEE;
    box-sizing: border-box;
    padding: 0 0 15px 0;
}

.codics-factory-admin-home-echarts {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

.codics-factory-admin-home-echarts-card-header {
    height: 40px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    line-height: 40px;
    font-size: 14px;
    font-weight: bolder;
    pointer-events: none;
}

.codics-factory-admin-home-echarts-card-header-quan {
    height: 100%;
    width: 36px;
    position: relative;
    float: left;
}

.codics-factory-admin-home-echarts-card-header-quan::after {
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: -2px;
    bottom: 0;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid #62CCA2;
}

.codics-factory-admin-home-echarts-card-header-more {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    line-height: 40px;
    font-size: 14px;
    font-weight: bolder;
    color: #666;
    padding-right: 15px;
    cursor: pointer;
    pointer-events: all;
}
</style>