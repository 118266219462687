<template>
    <div class="codics-factory-admin-menu-body">
        <div class="codics-factory-admin-menu-control">
            <el-button class="codics-factory-admin-menu-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;" plain>添加菜单</el-button>
            <!-- 添加菜单 -->
            <el-dialog title="添加商品菜单" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="菜单名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.name" autocomplete="off" maxlength="15" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="父级菜单" :label-width="formLabelWidth">
                        <el-cascader :options="menuList" clearable v-model="pidData"
                            :props="{ value: 'id', label: 'name', checkStrictly: true, children: 'children' }"
                            @change="selectChange">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label=" 菜单图标" :label-width="formLabelWidth">
                        <el-input v-model="addForm.icon" autocomplete="off" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="菜单路径" :label-width="formLabelWidth">
                        <el-input v-model="addForm.remark" autocomplete="off" maxlength="100"
                            show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="菜单权重" :label-width="formLabelWidth">
                        <el-input-number v-model="addForm.weight" :min="0" :max="1000" label=""></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addMenuFuc">确 定</el-button>
                </div>
            </el-dialog>
            <!-- <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="delMenusFuc">
                <el-button class="codics-factory-admin-menu-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm> -->
        </div>
        <el-table stripe class="codics-factory-admin-menu-table" :data="menuList" border style="width: 100%"
            row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" default-expand-all
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="主键" width="180" style="user-select: none;">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="180">
                <template slot-scope="scope">
                    <i class="icontfont" v-html="scope.row.name"></i>
                </template>
            </el-table-column>
            <el-table-column prop="icon" label="图标" width="50">
                <template slot-scope="scope">
                    <i :class="scope.row.icon"></i>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="路径" width="180">
            </el-table-column>
            <el-table-column prop="weight" label="权重" width="80">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delMenusFuc([scope.row.id])">
                        <el-button size="mini" v-show="scope.row.menu == null" type="danger"
                            slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-menu-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="dataSize" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
    </div>
</template>

<script>
import { listMenu, addMenu, deleteMenu } from '@/api';
export default {
    data() {
        return {
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                pid: 0,
                name: '',
                icon: '',
                remark: '',
                weight: 0
            },
            formLabelWidth: '120px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            menuList: [],
            addType: [],
            selectData: [],
            pidData: []
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listMenuData();

    },
    methods: {
        async listMenuData() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listMenu();

            loading.close();

            if (data.code == 200) {

                this.menuList = data.data;

                this.maxSize = data.count;

                this.hasChildren(this.menuList);

            } else {

                this.$message.error(data.message);

            }

        },
        async addMenuFuc() {

            if (this.addForm.name == '') {

                this.$notify({
                    title: '警告',
                    message: '填写的数据不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addMenu(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加菜单成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addProductBrand(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        hasChildren(list) {

            for (var i = 0; i < list.length; i++) {

                if (list[i]?.children != null) {

                    // list[i].hasChildren = true;

                    this.hasChildren(list[i].children);

                } else {

                    // delete list[i].children

                }

            }

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async delMenusFuc(t) {
            if (t == null) {
                if (this.selectData.length == 0) {
                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });
                    return true;
                }
                let t = [];
                this.selectData.forEach(item => {
                    t[t.length] = item.id;
                });
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteMenu({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        selectChange(a) {
            this.addForm.pid = a[a.length - 1] || 0;
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-menu-body {
    height: 100%;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
    overflow-y: scroll;
}

/* 后台管理页面主页 */
.codics-factory-admin-menu-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-menu-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-menu-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-menu-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-menu-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>