<template>
    <div class="codics-factory-admin-warn-body">
        <div class="codics-factory-admin-warn-control">
            <el-input class="codics-factory-admin-warn-control-search" style="width: 300px !important;"
                placeholder="请输入账号/昵称/邮箱/手机号..." v-model="searchTxt" clearable @input="searchChange">
                <el-button style="padding: 0 0px 0 15px;margin-right: 0 !important;" slot="append" icon="el-icon-search"
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listWarnFuc();"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteWarnsFuc">
                <el-button class="codics-factory-admin-device-control-search" slot="reference" type="danger"
                    icon="el-icon-delete" plain>批量删除</el-button>
            </el-popconfirm>
            <el-button class="codics-factory-admin-device-control-search" slot="reference" type="info"
                icon="el-icon-mobile-phone" plain @click="phoneVisible = true;">绑定手机</el-button>
            <el-button class="codics-factory-admin-device-control-search iconfont" slot="reference" type="info" plain
                @click="wxVisible = true;">绑定微信</el-button>
            <el-button class="codics-factory-admin-warn-control-search"
                style="float: right !important;margin-right: 0 !important;" icon="el-icon-download"
                @click="tableToExcel">导出数据</el-button>
            <el-button type="primary" style="margin-left: 0 !important;" plain @click="thodVisible = true;"><a
                    class="iconfont">&#xe83a;</a>
                设置阈值（温度：{{ thodNum != null ? (thodNum + '℃') : '未设置' }}；湿度：{{ humNum != null ? (humNum + 'RH') :
                '未设置' }}；）</el-button>
            <el-switch @change="switchSmsFuc" v-model="emsState"
                style="position: relative !important;float: left;top: 10px;margin-right: 15px;" active-color="#13ce66"
                inactive-color="#CCC" active-text="短信提醒" inactive-text="不提醒">
            </el-switch>
            <el-dialog title="设置阈值" :visible.sync="thodVisible" width="80%" :close-on-click-modal='false'>
                <el-form :model="form">
                    <el-form-item label="温度阈值" :label-width="formLabelWidth">
                        <el-input-number v-model="thodNum" :precision="2" :step="0.1" :max="100"
                            style="margin-right: 90%;margin-bottom: 15px;"></el-input-number>
                    </el-form-item>
                </el-form>
                <el-form :model="form">
                    <el-form-item label="湿度阈值" :label-width="formLabelWidth">
                        <el-input-number v-model="humNum" :precision="2" :step="0.1" :max="100"
                            style="margin-right: 90%;margin-bottom: 15px;"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="setInfoFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="绑定手机号" :visible.sync="phoneVisible" width="80%" :close-on-click-modal='false'>
                <el-form :model="form">
                    <el-form-item label="手机号" :label-width="formLabelWidth">
                        <el-input v-model="phoneNum" autocomplete="off"
                            oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请输入手机号" type="number"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="phoneVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindPhoneFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="绑定微信" :visible.sync="wxVisible" width="80%" :close-on-click-modal='false'>
                <el-form :model="form">
                    <el-image :src="qrImg" style="position: relative;left: 50%;transform: translateX(-50%);" fit="cover"></el-image>
                </el-form>
            </el-dialog>
        </div>
        <el-table stripe class="codics-factory-admin-warn-table" :data="searchTxt == '' ? deviceList : searchData"
            border style="width: 100%" @selection-change="handleSelectionChange" ref="userTable">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="主键" width="70">
            </el-table-column>
            <el-table-column prop="rid" label="记录编号" width="90">
            </el-table-column>
            <el-table-column prop="type" label="报警类型" width="95"
                :filters="[{ text: '独设温度', value: 0 }, { text: '统设温度', value: 1 }, { text: '独设湿度', value: 2 }, { text: '统设湿度', value: 3 }]"
                :filter-method="typeFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.type == 0 ? 'success' : (scope.row.type == 1 ? 'warning' : (scope.row.type == 2 ? 'danger' : 'info'))"
                        disable-transitions>{{ scope.row.type == 0 ? '独设温度' : (scope.row.type == 1 ? '统设温度' :
                        (scope.row.type == 2 ? '独设湿度' : '统设湿度')) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="imei" label="物联网编号" width="180">
            </el-table-column>
            <el-table-column prop="icode" label="类型" width="70"
                :filters="[{ text: '温度', value: '22' }, { text: '湿度', value: '23' }]" :filter-method="icodeFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.icode == 22 ? 'success' : 'danger'" disable-transitions>{{ scope.row.icode
                        ==
                        22 ? '温度' : '湿度' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="temp" label="触发温度(℃)" width="80">
            </el-table-column>
            <el-table-column prop="hum" label="触发湿度(RH)" width="80">
            </el-table-column>
            <el-table-column prop="nlnum" label="触发阈值" width="80">
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="220">
            </el-table-column>
            <el-table-column prop="updatetime" label="上传时间" width="250">
                <template slot-scope="scope">
                    {{ initTime(scope) }}
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteWarnsFuc([scope.row.id])">
                        <el-button v-show="scope.row.children == null" size="mini" type="danger"
                            slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-warn-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { listDevice, bindDevices, searchUsers, bindThod, deleteDevices, thInfo, setInfo, listWarn2, deleteWarn, bindPhone, userInfos, smsState, switchSms } from '@/api';
import { setCookie,getCookie } from '@/utils/mycookie';
import { exportExcel } from '@/utils/myutils';
export default {
    data() {
        return {
            emsState: false,
            wxVisible:false,
            phoneVisible: false,
            bindVisible: false,
            thodVisible: false,
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            deviceList: [],
            selectData: [],
            usersOptions: [],
            userLoading: false,
            selectUserData: '',
            thodNum: null,
            humNum: null,
            nowSearchTxt: '',
            phoneNum: '',
            qrImg:''
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listWarnFuc();

        this.thInfoFuc();

        this.phoneNum = this.userInfo.user.phone;

        this.smsStateFuc();

        this.initQrcode();

    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        async initQrcode() {

            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 1,//二维码留白边距
                width: 100,//宽
                height: 100,//高
                text: 'https://factory.katihuo.com/api/wx/jumpb?pkey=' + getCookie('x-myweb-key') + "&id=" + this.userInfo?.user?.id,//二维码内容
                color: {
                    dark: "#000",//前景色
                    light: "#fff"//背景色
                }
            };
            await QRCode.toDataURL(opts.text, opts, (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                // console.log(url)
                this.qrImg = url;
            });

        },
        async smsStateFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await smsState();

            loading.close();

            if (data.code == 200) {

                this.emsState = data.data != null;

            } else {

                this.$message.error(data.message);

            }

        },
        async switchSmsFuc(state) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await switchSms();

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.message,
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        initTime(scope) {
            return dayjs(scope.row.updatetime).format('YYYY-MM-DD HH:mm:ss');
        },
        async bindPhoneFuc() {

            if (this.phoneNum == null || this.phoneNum == '') {

                this.$message({
                    message: '请先输入手机号！',
                    type: 'info'
                });

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindPhone({ 'phone': this.phoneNum });

            loading.close();

            this.phoneVisible = false;

            if (data.code == 200) {

                this.$message({
                    message: '手机号绑定成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleBindClick() {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.bindVisible = true;

        },
        async deleteWarnsFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteWarn({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async thInfoFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await thInfo({});

            loading.close();

            if (data.code == 200) {

                this.thodNum = data.thod;

                this.humNum = data.humth;

            } else {

                this.$message.error(data.message);

            }

        },
        handleThodClick(c) {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.thodVisible = true;

        },
        icodeFilter(v, r, c) {

            return r.icode === v;

        },
        typeFilter(v, r, c) {

            return r.type === v;

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        async bindDevicesFuc(sid, ids) {

            if (sid == null || sid == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的商户数据！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindDevices({ sid: sid, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindThodFuc(thod, ids) {

            if (thod == null || thod == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindThod({ thod: thod, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleUserTagClose(tag) {
            this.$refs.userTable.selection.splice(this.$refs.userTable.selection.indexOf(tag), 1);
            this.selectData = this.$refs.userTable.selection;
            if (this.selectData.length == 0) {
                this.bindVisible = false;
                this.thodVisible = false;
            }
        },
        async listWarnFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listWarn2({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.nowSearchTxt == '') {

                    this.deviceList = data.data;

                    this.maxSize = data.count;

                    setCookie('com-factory-left-menu-warn-count', data.count);

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
            } else {
                this.searchSize = val;
            }
            this.listWarnFuc();
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
            } else {
                this.searchPage = val;
            }
            this.listWarnFuc();
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductBrand(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async setInfoFuc() {

            if (this.thodNum == null || this.humNum == null) {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await setInfo({ thod: this.thodNum, humth: this.humNum });

            loading.close();

            this.thodVisible = false;

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '记录编号', '报警类型', "物联网编号", "触发温度（℃）", "触发湿度（RH）", '设备类型', "设备状态", "报警时间", "备注信息", "触发阈值"];
            let filter = [];  //不导出的字段
            exportExcel(this.selectData, '报警数据_' + year + month + day, title, filter);
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-warn-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-factory-admin-warn-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-warn-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-warn-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-warn-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-warn-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-factory-admin-warn-signal {
    height: 16px !important;
    width: 16px !important;
}
</style>