<template>
    <div>
        <template v-for="(item, index) in menuList">
            <!-- 有次级菜单的,则展开子选项-->
            <el-submenu v-if="item.children && item.children.length > 0" :key="index" :index="item.id" router>
                <template slot="title">
                    <i class="iconfont" style="margin-right: 13px;" v-html="item.icon"></i>
                    <span v-html="item.name" style="font-weight:bolder;font-size:12px;"></span>
                </template>
            </el-submenu>
            <!-- 没有次级菜单的 -->
            <el-menu-item class="codics-menutree-item" v-if="!item.children" :key="index" @click="routerGo(item, index)"
                :index="item.remark" :class="activeMenu == index ? 'activeMenu' : ''">
                <i class="iconfont" style="margin-right: 13px;" v-html="item.icon"></i>
                <span v-html="item.name" style="font-weight:bolder;font-size:12px;"></span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>

export default {
    name: 'MenuTree',
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        },
        menuList: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            chosed: '',
            activeMenu: '0'
        }
    },
    mounted() {
        // console.log(this.menuList, '123');
        //  this.$emit('childEvent',i)
    },
    methods: {
        refresh(t) {

            this.menuList = t;

        },
        // 菜单点击的操作
        routerGo(i, index) {
            // console.log(window.location.href,i.remark)
            // if (!window.location.href.toString().includes(i.remark)) {
            //     this.$router.push(i.remark);
            // }
            this.$emit('childEvent', i)
            this.chosed = index
        },
        // 接受本身组件传来的参数，然后将传来得值，发送到外层父组件
        chosedMenu(i) {
            this.$emit('childEvent', i)
        },

    }
}
</script>
<style lang='css'>
.activeMenu {
    color: #333 !important;
    /* background: #333 !important; */
}

/* 菜单栏选中后背景色 */
.el-menu-item {
    color: #333;
}

.codics-menutree-item .is-active {
    color: #333;
    /* background: #333 !important; */
    border-left: 1px solid #E6A23C;
}

/* 选中菜单后的操作
.is-active {
    background: rgba(0, 0, 0, 0) !important;
} */
</style>