<template>
    <div class="codics-factory-admin-home-home">
        <marquee class="codics-factory-admin-home-pmd">欢迎用户：<a style="color: #62CCA2;cursor: pointer;"
                @click="jumpToFuc('个人中心', {}, {})">{{ userInfo.user.nickname || '' }}</a>，当前时间：<a>{{ getCurrentTime()
                }}</a>
        </marquee>
        <fieldset v-show="false" class="codics-factory-admin-home-sjtj" style="margin-bottom: 15px;">
            <legend>数据统计</legend>
            <router-link class="codics-factory-admin-home-sjtj-card" v-for="item in sjtjData" :to="item.path"
                v-bind:key="item.id">
                <div class="codics-factory-admin-home-sjtj-box">
                    <div class="codics-factory-admin-home-sjtj-title">{{ item.title }}</div>
                    <i :class="item.icon + ' codics-factory-admin-home-sjtj-icon iconfont'" v-html="item.icon"></i>
                    <div class="codics-factory-admin-home-sjtj-count">{{ item.count }}</div>
                </div>
            </router-link>
        </fieldset>
        <div class="codics-factory-admin-home-line">
            <div class="codics-factory-admin-home-sjtj3" :style="{ 'margin-right': '15px' }" data-content="第1号冷库">
                <div id="eChart" class="codics-factory-admin-home-echarts"></div>
                <div class="codics-factory-admin-home-device-bind" @click="deviceDialog = true;">绑定设备</div>
            </div>
            <div class="codics-factory-admin-home-sjtj3" data-content="冷库温度地图" style="padding-bottom: 0 !important;">
                <div id="eChart2" class="codics-factory-admin-home-echarts" style="padding-bottom: 0 !important;"></div>
                <svg v-show="mapState" class="icon codics-factory-admin-map-default" aria-hidden="true" title="工厂缺省">
                    <use xlink:href="#icon-wangluoyichang" title="工厂缺省"></use>
                </svg>
            </div>
        </div>
        <el-dialog title="绑定设备" :visible.sync="deviceDialog">
            <el-form :model="form">
                <el-form-item label="温度设备" :label-width="formLabelWidth">
                    <el-select v-model="deviceImei[0]" placeholder="请选择温度设备" @change="tempChange">
                        <el-option v-for="(item, index) in tempImeis" :key="index" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="false" label="湿度设备" :label-width="formLabelWidth">
                    <el-select v-model="deviceImei[1]" placeholder="请选择湿度设备" @change="humChange">
                        <el-option v-for="(item, index) in humImeis" :key="index" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deviceDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import svgData from '../../../../public/factory/huayuan.svg';
import { mapState } from 'vuex';
import { currentDate } from '@/utils/myutils';
import { listRecords, mapInfo, pickDevices2, warnCount } from '@/api';
import { jumpTo } from "@/utils/myutils";
import { setCookie, getCookie } from '@/utils/mycookie';

export default {
    data() {
        return {
            mapState: false,
            formLabelWidth: '120px',
            deviceDialog: false,
            myChart: null,
            myChart2: null,
            deviceImei: [getCookie("com-huayuan-factory-home-temp"), getCookie("com-huayuan-factory-home-hum")],
            sjtjData: [
                {
                    id: 1,
                    title: '员工数量',
                    icon: '&#xe88a;',
                    count: '*',
                    path: '/firm/user'
                },
                {
                    id: 2,
                    title: '菜单管理',
                    icon: '&#xe6fd;',
                    count: '*',
                    path: '/firm/menu'
                },
                {
                    id: 3,
                    title: '工厂管理',
                    icon: '&#xe6bc;',
                    count: '*',
                    path: '/firm/factory'
                },
                {
                    id: 4,
                    title: '设备管理',
                    icon: '&#xe741;',
                    count: '*',
                    path: '/firm/device'
                },
                {
                    id: 5,
                    title: '设备报警',
                    icon: '&#xe83b;',
                    count: '*',
                    path: '/firm/warn'
                },
                {
                    id: 6,
                    title: '操作日志',
                    icon: '&#xe85c;',
                    count: '*',
                    path: '/firm/log'
                }
            ],
            isPhone: false,
            tempImeis: [],
            humImeis: []
        }
    },
    mounted() {
        // this.adminInfoFuc();
        // this.initChart();
        this.$emit('check-mem');
        this.listRecordsFuc();
        this.mapInfoFuc();
        this.pickDevicesFuc();
        this.warnCountFuc();
        this.watchSize('eChart', (width, height) => {
            this.myChart && this.myChart.resize();
        });
        this.watchSize('eChart2', (width, height) => {
            this.myChart2 && this.myChart2.resize();
        });


    },
    methods: {
        async warnCountFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await warnCount();

            loading.close();

            if (data.code == 200) {

                this.sjtjData[0].count = data.ucount;

                this.sjtjData[3].count = data.dcount;

                this.sjtjData[4].count = data.count;

                let t = this.sjtjData;

                this.sjtjData = [];

                this.sjtjData = t;

            } else {

                this.$message.error(data.message);

            }

        },
        tempChange(a) {

            setCookie("com-huayuan-factory-home-temp", a);

            this.$message({
                message: '数据绑定成功！',
                type: 'success'
            });

            setTimeout(() => {

                window.location.reload();

            }, 300);

        },
        humChange(a) {

            setCookie("com-huayuan-factory-home-hum", a);

            this.$message({
                message: '数据绑定成功！',
                type: 'success'
            });

            setTimeout(() => {

                window.location.reload();

            }, 300);

        },
        async pickDevicesFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await pickDevices2();

            loading.close();

            if (data.code == 200) {

                for (let i in data.temp) {

                    this.tempImeis.push({
                        label: data.temp[i].pcode,
                        value: data.temp[i].imei
                    });

                }

                for (let j in data.hum) {

                    this.tempImeis.push({
                        label: data.hum[j].pcode,
                        value: data.hum[j].imei
                    });

                }

                if (this.tempImeis.length != 0) {

                    this.listRecordsFuc(getCookie("com-huayuan-factory-home-temp"), getCookie("com-huayuan-factory-home-temp"));

                }

            } else {

                this.$message.error(data.message);

            }

        },
        async mapInfoFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await mapInfo();

            loading.close();

            if (data.code == 200) {

                if (data.data) {

                    this.initChart2(data);

                } else {

                    this.mapState = true;

                }

                this.deviceList = data.record;

            } else {

                this.$message.error(data.message);

            }

        },
        initChart(data) {

            let length = data.list1.length == 0 ? data.data.length : data.list1.length;

            let nowDate = Date.now();

            let t = Array.from({ length }, (_, i) => nowDate - i * 1800000);

            let n = new Date().getDay();

            t = t.map((n) => {

                let date = new Date(n);

                let r = n == date.getDay() ? '' : '昨日 ';

                let hour = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());

                let menute = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());

                let second = date.getSeconds() >= 10 ? date.getSeconds() : ('0' + date.getSeconds());

                return `${hour}:${menute}:${second}`;

            });

            t[0] = '现在';

            let d = (data.list1.length==0 ? data.data : data.list1).map(function (item, index) {
                return item?.temp; // 返回值可以用于创建新数组
            });

            let d2 = (data.list1.length == 0 ? data.data : data.list1).map(function (item, index) {
                return item?.hum; // 返回值可以用于创建新数组
            });

            console.log(d,d2)

            var chartDom = document.getElementById('eChart');

            this.myChart = echarts.init(chartDom);

            var option;

            option = {
                title: {
                    text: '',
                    show: false
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    y: 'bottom',
                    left: 'center'
                },
                toolbox: {
                    show: true,
                    top: 10,
                    right: 10,
                    itemSize: 11.5,
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: t.reverse()
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                        data: t
                    }
                ],
                //     {
                //     type: 'category',
                //         boundaryGap: false,
                //             data: t.reverse()
                // }
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        name: '温度',
                        boundaryGap: [0.2, 0.2],
                        axisLabel: {
                            formatter: '{value} °C'
                        }
                    },
                    {
                        type: 'value',
                        scale: true,
                        name: '湿度',
                        formatter: '{value} %RH',
                        boundaryGap: [0.2, 0.2]
                    }
                ],
                // {
                //     type: 'value',
                //     axisLabel: {
                //         formatter: '{value} °C'
                //     }
                // },
                grid: {
                    top: 80
                },
                series: [
                    {
                        name: '仓库温度',
                        type: 'line',
                        smooth: true,
                        data: d,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ],
                            // itemStyle: {
                            //     normal: {
                            //         borderColor: 'red',
                            //         borderWidth: 2
                            //     },
                            //     emphasis: {
                            //         borderColor: 'blue',
                            //         borderWidth: 3
                            //     }
                            // }
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        },
                        // lineStyle: {
                        //     normal: {
                        //         color: function (params) {
                        //             if (params.value > 28) {
                        //                 return '#FF0000'; // 超过特定值后的线条颜色
                        //             } else {
                        //                 return '#5c7bd9'; // 默认颜色
                        //             }
                        //         },
                        //     },
                        //     emphasis: {
                        //         color: function (params) {
                        //             if (params.value > 28) {
                        //                 return '#FF0000'; // 超过特定值后的线条颜色
                        //             } else {
                        //                 return '#5c7bd9'; // 默认颜色
                        //             }
                        //         }
                        //     }
                        // },
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    // 自定义判断逻辑，如果数据大于特定值则改变颜色
                                    if (params.value > 30) {
                                        return '#FF0000'; // 超过特定值后的点颜色
                                    } else {
                                        return '#5c7bd9'; // 默认颜色
                                    }
                                }
                            }
                        }
                        // },
                        // lineStyle: {
                        //     normal: {
                        //         color: function (params) {
                        //             if (params.value > 28) {
                        //                 return '#FF0000'; // 超过特定值后的线条颜色
                        //             } else {
                        //                 return '#5c7bd9'; // 默认颜色
                        //             }
                        //         }
                        //     }
                        // }
                    },
                    {
                        name: '仓库湿度',
                        type: 'bar',
                        smooth: true,
                        data: d2,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        markPoint: {
                            data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                        },
                        markLine: {
                            data: [
                                { type: 'average', name: 'Avg' },
                                [
                                    {
                                        symbol: 'none',
                                        x: '90%',
                                        yAxis: 'max'
                                    },
                                    {
                                        symbol: 'circle',
                                        label: {
                                            position: 'start',
                                            formatter: 'Max'
                                        },
                                        type: 'max',
                                        name: '最高点'
                                    }
                                ]
                            ]
                        }
                    }
                ]
            };

            option && this.myChart.setOption(option);

        },
        initChart2(data) {

            let _data = data;

            let t = data.record;

            data = [];

            for (let idx in t) {

                data.push({
                    name: t[idx].pcode,
                    value: t[idx].temp
                });

            };

            let geoCoordMap = {};

            for (let idx in t) {

                geoCoordMap[t[idx].pcode] = [t[idx].x, t[idx].y];

            };

            const _this = this;
            const takenSeatNames = ['22号冷库'];
            const chartDom = document.getElementById("eChart2")
            this.myChart2 = echarts.init(chartDom)
            fetch('https://cdn.bosscaigou.com/' + _data.data.dmap).then(response => response.text()).then(svgText => {
                echarts.registerMap('factory', { svg: svgText });
                var convertData = function (data) {
                    var res = [];
                    for (var i = 0; i < data.length; i++) {
                        var geoCoord = geoCoordMap[data[i].name];
                        if (geoCoord) {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value)
                            });
                        }
                    }
                    return res;
                };
                var option = {
                    geo: {
                        map: 'factory',
                        roam: true,
                        selectedMode: 'multiple',
                        layoutCenter: ['50%', '50%'],
                        layoutSize: '80%',
                        itemStyle: {
                            color: '#fff'
                        },
                        emphasis: {
                            itemStyle: {
                                color: undefined,
                                borderColor: 'green',
                                borderWidth: 2
                            },
                            label: {
                                show: true
                            }
                        },
                        select: {
                            itemStyle: {
                                color: 'green'
                            },
                            label: {
                                show: true,
                                textBorderColor: '#fff',
                                textBorderWidth: 2
                            }
                        },
                        regions: _this.makeTakenRegions(takenSeatNames)
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: '设备温度(℃)',
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            data: convertData(
                                data.sort(function (a, b) {
                                    return b.value - a.value;
                                }).slice(0, 6)
                            ),
                            encode: {
                                value: 2
                            },
                            symbolSize: 9,
                            showEffectOn: 'emphasis',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                formatter: '{b}',
                                position: 'right',
                                show: true
                            },
                            itemStyle: {
                                color: '#333',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            zlevel: 1
                        },
                    ]
                };
                this.myChart2.on('geoselectchanged', function (params) {
                    const selectedNames = params.allSelected[0].name.slice();
                    // Remove taken seats.
                    for (var i = selectedNames.length - 1; i >= 0; i--) {
                        if (takenSeatNames.indexOf(selectedNames[i]) >= 0) {
                            selectedNames.splice(i, 1);
                        }
                    }
                    // console.log('selected', selectedNames);
                });
                option && this.myChart2.setOption(option);

            });

        },
        makeTakenRegions(takenSeatNames) {
            var regions = [];
            for (var i = 0; i < takenSeatNames.length; i++) {
                regions.push({
                    name: takenSeatNames[i],
                    silent: true,
                    itemStyle: {
                        color: '#FFF'
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#333',
                            borderWidth: 1
                        },
                        label: {
                            show: true
                        }
                    },
                    select: {
                        itemStyle: {
                            color: '#333'
                        },
                        label: {
                            show: true
                        }
                    }
                });
            }
            return regions;
        },
        watchSize(id, fuc) {

            const observedElement = document.getElementById(id);
            // 创建一个ResizeObserver实例并定义回调函数
            const resizeObserver = new ResizeObserver(entries => {

                for (let entry of entries) {

                    const width = entry.contentRect.width;

                    const height = entry.contentRect.height;

                    fuc(width, height);

                }

            });
            // 开始观察元素
            resizeObserver.observe(observedElement);

        },
        async listRecordsFuc(a, b) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listRecords({ imei: a || this.tempImeis[0], imei2: b || this.humImeis[0], icode: 22, pge: 1, psz: 10 });

            loading.close();

            if (data.code == 200) {

                this.initChart(data)

            } else {

                this.$message.error(data.message);

            }
        },
        getCurrentTime() {
            return currentDate();
        },
        async adminInfoFuc() {
            let data = await adminInfo();
            if (data.code == 200) {
                this.sjtjData[0].count = data.ucount;
                this.sjtjData[3].count = data.scount;
                this.sjtjData[4].count = data.ccount;
            } else {
                this.$message.error(data.message);
            }
        },
        jumpToFuc(...data) {
            jumpTo(this, ...data)
        },
        refreshCard(data) {

            this.sjtjData[0].count = data.ucount;

            this.sjtjData[3].count = data.dcount;

            this.sjtjData[4].count = data.count;

            let t = this.sjtjData;

            this.sjtjData = [];

            this.sjtjData = t;

        }
    },
    computed: {
        ...mapState(['userInfo']),
        nowTime() { 
            return getCurrentTime();
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-home-home {
    height: 100%;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 15px;
    overflow-x: hidden;
}

/* 主页跑马灯公告 */
.codics-factory-admin-home-pmd {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 20px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-left: 2px solid #62CCA2;
    margin-bottom: 10px;
    font-size: 13px !important;
}

/* 主页数据统计 */
.codics-factory-admin-home-sjtj {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border: 1px solid #DCDFE6;
    margin-bottom: 10px;
}

/* 主页数据统计卡片 */
.codics-factory-admin-home-sjtj-card {
    height: 110px;
    width: 16.6666667%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    color: #333 !important;
}

/* 主页数据统计卡片盒子 */
.codics-factory-admin-home-sjtj-box {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #f8f8f8;
    cursor: pointer;
    user-select: none;
}

/* 主页数据统计标题控件 */
.codics-factory-admin-home-sjtj-title {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
    line-height: 40px;
    font-size: 15px;
    color: #333;
}

/* 主页数据统计图标 */
.codics-factory-admin-home-sjtj-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

/* 主页数据统计数据 */
.codics-factory-admin-home-sjtj-count {
    height: 36px;
    width: fit-content;
    position: absolute;
    line-height: 36px;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
}

.table-sysinfo {
    line-height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.codics-factory-admin-home-home table,
.codics-factory-admin-home-home td,
.codics-factory-admin-home-home th {
    border-collapse: collapse;
    border-spacing: 10px;
    border: 1px solid #DDD;
    min-width: 45px;
}

.codics-factory-admin-home-home td {
    padding-left: 10px;
}

.codics-factory-admin-home-home table tr:nth-child(even) {
    background: #f8f8f8;
}

@media (max-width: 768px) {
    .codics-factory-admin-home-sjtj-card {
        width: 50%;
    }
}

.codics-factory-admin-home-line {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 2px;
    margin-bottom: 10px;
}

.codics-factory-admin-home-echarts {
    height: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.codics-factory-admin-home-sjtj2 {
    width: calc(50% - 10px) !important;
}

.codics-factory-admin-home-sjtj3 {
    height: 380px;
    width: calc(50% - 7.5px);
    position: relative;
    float: left;
    box-sizing: border-box;
    border: 1px solid #DCDFE6;
    padding-bottom: 10px;
}

.codics-factory-admin-home-sjtj3::before {
    content: attr(data-content);
    height: 20px;
    width: fit-content;
    position: absolute;
    top: -10px;
    left: 12px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #333;
    background: #FFF;
}

.codics-factory-admin-home-device-bind {
    height: 50px;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 40px;
    line-height: 50px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
}
</style>