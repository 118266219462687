<template>
    <div class="codics-factory-admin-body">
        <AdminHeader @changeCollapse="changeCollapse" :is-collapse="isCollapse" ref="adminHeader"></AdminHeader>
        <div class="codics-factory-admin-view">
            <div class="codics-factory-admin-left-box"
                :style="{ width: isCollapse ? '45px !important' : '250px !important' }">
                <AdminLeft :is-collapse="isCollapse" ref="adminLeft"></AdminLeft>
            </div>
            <router-view class="codics-factory-admin-content"
                :style="{ width: isCollapse ? 'calc(100% - 47px)' : 'calc(100% - 252px)' }"
                ref="routeViw"></router-view>
        </div>
    </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader';
import AdminLeft from '@/components/AdminLeft';
import { warnCount } from '@/api';
import { getCookie } from '@/utils/mycookie';
export default {
    data() {
        return {
            isCollapse: !Boolean(getCookie("com-factory-admin-left-state") == null || getCookie("com-factory-admin-left-state") == 'false')
        }
    },
    methods: {
        changeCollapse() {
            this.isCollapse = !this.isCollapse;
        },
        async warnCountFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await warnCount();

            loading.close();

            if (data.code == 200) {

                this.$refs.adminLeft && this.$refs.adminLeft.refresh(data.count);

                this.$refs.adminHeader && this.$refs.adminHeader.refresh(data);

                this.$refs.routeViw.refreshCard && this.$refs.routeViw.refreshCard(data);

            } else {

                this.$message.error(data.message);

            }

        }
    },
    mounted() {
        this.warnCountFuc();
        console.log(131231231, Boolean(getCookie("com-factory-admin-left-state")))
    },
    components: {
        AdminHeader,
        AdminLeft
    }
}
</script>

<style>
.codics-factory-admin-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-factory-admin-view {
    height: calc(100% - 46px);
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
}

.codics-factory-admin-left-box {
    height: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    transition: .3s;
    border-right: 1px solid #F0F0F0;
}

.codics-factory-admin-content {
    height: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    transition: .3s;
    overflow-y: scroll !important;
    background: #F0F0F0;
}
</style>