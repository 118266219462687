<template>
    <div class="codics-factory-admin-record-body">
        <div class="codics-factory-admin-record-control">
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteRecordsFuc">
                <el-button class="codics-factory-admin-record-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
            <el-button class="codics-factory-admin-warn-control-search"
                style="float: right !important;margin-right: 0 !important;" icon="el-icon-download"
                @click="tableToExcel">导出数据</el-button>
            <el-input class="codics-factory-admin-record-control-search"
                style="width: 300px !important;float: right !important;margin-right: 15px !important;"
                placeholder="请输入账号/昵称/邮箱/手机号..." v-model="searchTxt" clearable @input="searchChange">
                <el-button style="padding: 0 15px 0 15px;" slot="append" icon="el-icon-search"
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listRecordsFuc();"></el-button>
            </el-input>
            <el-dialog title="绑定设备" :visible.sync="bindVisible" width="80%" :close-on-click-modal='false'>
                <el-select v-model="selectUserData" style="width: 100%;margin-bottom: 10px;" filterable remote
                    reserve-keyword placeholder="请输入用户账号/昵称/邮箱/手机号" :remote-method="searchUsersFuc"
                    :loading="userLoading" clearable>
                    <el-option v-for="(item, index) in usersOptions" :key="index" :label="item.nickname"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-tag v-for="tag in selectData" :key="tag.imei" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.imei }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindDevicesFuc(selectUserData)">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="设置阈值" :visible.sync="thodVisible" width="80%" :close-on-click-modal='false'>
                <el-input-number v-model="thodNum" :precision="2" :step="0.1" :max="100"
                    style="margin-right: 90%;margin-bottom: 15px;"></el-input-number>
                <el-tag v-for="tag in selectData" :key="tag.imei" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.imei }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindThodFuc(thodNum)">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <el-table stripe class="codics-factory-admin-record-table" :data="searchTxt == '' ? deviceList : searchData"
            border style="width: 100%" @selection-change="handleSelectionChange" ref="userTable">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="主键" width="70">
            </el-table-column>
            <el-table-column prop="pcode" label="设备码" width="180" style="user-select: none;">
            </el-table-column>
            <el-table-column prop="imei" label="物联网编号" width="180">
            </el-table-column>
            <el-table-column prop="icode" label="类型" width="70"
                :filters="[{ text: '温度', value: '22' }, { text: '湿度', value: '23' }]" :filter-method="icodeFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.icode == 22 ? 'success' : 'danger'" disable-transitions>{{ scope.row.icode
                ==
                22 ? '温度' : '湿度' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="temp" label="温度(℃)" width="80">
            </el-table-column>
            <el-table-column prop="hum" label="湿度(RH)" width="80">
            </el-table-column>
            <el-table-column prop="himg" label="信号" width="50">
                <template slot-scope="scope">
                    <svg class="icon codics-factory-admin-record-signal" aria-hidden="true">
                        <use
                            :xlink:href="scope.row.sgal > 20 ? '#icon-xinhao' : (scope.row.sgal > 15 ? '#icon-xinhao-copy2' : (scope.row.sgal > 10 ? '#icon-xinhao-copy' : (scope.row.sgal > 5 ? '#icon-xinhao-copy-copy' : '#icon-xinhao-wu')))">
                        </use>
                    </svg>
                </template>
            </el-table-column>
            <el-table-column prop="himg" label="电量" width="50">
                <template slot-scope="scope">
                    <svg class="icon codics-factory-admin-record-signal" aria-hidden="true">
                        <use
                            :xlink:href="scope.row.voge > 3.8 ? '#icon-iconset0252' : (scope.row.voge > 3.6 ? '#icon-iconset0251' : (scope.row.voge > 3.4 ? '#icon-iconset0250' : (scope.row.voge > 3.2 ? '#icon-iconset0249' : '#icon-iconset0248')))">
                        </use>
                    </svg>
                </template>
            </el-table-column>
            <el-table-column prop="updatetime" label="上传时间" width="250">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteRecordsFuc([scope.row.id])">
                        <el-button v-show="scope.row.children == null" size="mini" type="danger"
                            slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-record-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { exportExcel } from '@/utils/myutils';
import { listDevice, bindDevices, searchUsers, bindThod, deleteDevices, listRecords2, deleteRecords } from '@/api';
export default {
    data() {
        return {
            bindVisible: false,
            thodVisible: false,
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            deviceList: [],
            selectData: [],
            usersOptions: [],
            userLoading: false,
            selectUserData: '',
            thodNum: 0,
            nowSearchTxt: ''
        }
    },
    mounted() {

        this.listRecordsFuc();

    },
    methods: {
        async deleteRecordsFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteRecords({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        icodeFilter(v, r, c) {

            console.log(v, r.icode)

            return r.icode === v;

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        async bindDevicesFuc(sid, ids) {

            if (sid == null || sid == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的商户数据！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindDevices({ sid: sid, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindThodFuc(thod, ids) {

            if (thod == null || thod == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindThod({ thod: thod, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindHumthFuc(humth, ids) {

            if (humth == null || humth == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindHumth({ humth: humth, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleUserTagClose(tag) {
            this.$refs.userTable.selection.splice(this.$refs.userTable.selection.indexOf(tag), 1);
            this.selectData = this.$refs.userTable.selection;
            if (this.selectData.length == 0) {
                this.bindVisible = false;
                this.thodVisible = false;
            }
        },
        async listRecordsFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listRecords2({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.nowSearchTxt == '') {

                    this.deviceList = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
            } else {
                this.searchSize = val;
            }
            this.listRecordsFuc();
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
            } else {
                this.searchPage = val;
            }
            this.listRecordsFuc();
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductBrand(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '设备编号', 'IMEI', "温度（℃）", "湿度（RH）", "IMSI", '数据类型', "设备状态", "电压", "信号强度", "记录时间"];
            let filter = [];  //不导出的字段
            exportExcel(this.selectData, '设备数据_' + year + month + day, title, filter);
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-record-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-factory-admin-record-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-record-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-record-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-record-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-record-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-factory-admin-record-signal {
    height: 16px !important;
    width: 16px !important;
}
</style>