<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { userInfos } from './api';
import { getCookie, setCookie, getCookieExp } from './utils/mycookie';
import { randomString, jumpTo } from '@/utils/myutils';
import { hex_md5 } from '@/utils/md5';
// 初始化设备唯一识别码
if (getCookie('x-myweb-key') == null) {
  //设置设备唯一识别码
  setCookie('x-myweb-key', hex_md5(new Date().valueOf().toString() + randomString(50)));

}
export default {
  name: 'App',
  mounted() {

    console.log(decodeURIComponent('https%3A%2F%2Ffactory.katihuo.com%2Fapi%2Fwx%2Fbind%3Fpkey%3D111%26id%3D22'))

    if (this.userInfo != null) {

      this.userInfoFuc();

    } else {



    }

  },
  methods: {

    async userInfoFuc() {

      let data = await userInfos();

      if (data.code == 200) {

        if (window.location.href.indexOf('/firm') != -1 && data.user.uar == null) {

          this.$message.error("您的账号没有访问当前页面的权限！");

          this.$store.dispatch('logout');

          jumpTo(this, '登录', {}, {});

        }

        setCookie("user-inf", JSON.stringify(data));
        // 刷新仓库数据
        this.$store.dispatch('login');

      } else {

        switch (data.code) {

          case 409:

          case 401:

            this.$store.dispatch('logout');

            jumpTo(this, '登录', {}, {});

            break;

        }

        this.$message.error(data.message);

      }

    }

  },
  components: {
  },
  computed: {
    ...mapState(['userInfo'])
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {
    return {
      title: this.$route.meta.metaInfo.title,
      meta: [
        { name: "keywords", content: this.$route.meta.metaInfo.keywords },
        { name: "description", content: this.$route.meta.metaInfo.description },
      ]
    }
  }
}
</script>

<style>
/* APP主体部分 */
#app {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  background: #FFF;
}
</style>
