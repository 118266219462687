import requests from './request';

import Qs from 'qs';

import { getCookie } from '@/utils/mycookie';
// 用户登录
export const userLogin = (d) => requests({ url: '/user/login', method: 'post', headers: { 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 刷新登录验证码
export const changeVerify = () => requests({ url: '/user/verify', method: 'get', headers: { 'x-codic': getCookie('x-myweb-key') } });
// 获取用户列表
export const getUserList = (d) => requests({ url: '/user/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 重置用户密码
export const rePass = (d) => requests({ url: '/user/repass', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询设备记录列表
export const listRecords = (d) => requests({ url: '/device/record/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询设备记录列表
export const listRecords2 = (d) => requests({ url: '/device/record/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询设备记录列表
export const listRecords3 = (d) => requests({ url: '/device/record/list3', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询用户列表
export const searchUsers2 = (d) => requests({ url: '/user/search2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 搜索员工角色
export const searchUars = (d) => requests({ url: '/uar/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询菜单
export const listMenu = (d) => requests({ url: '/menu/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 分页查询设备
export const listDevice = (d) => requests({ url: '/device/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 分页查询设备2
export const listDevice2 = (d) => requests({ url: '/device/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 商户绑定设备
export const bindDevices = (d) => requests({ url: '/device/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 商户绑定设备
export const pickDevices = (d) => requests({ url: '/device/pick', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 商户绑定设备
export const pickDevices2 = (d) => requests({ url: '/device/pick2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 搜索平台商户列表
export const searchUsers = (d) => requests({ url: '/user/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量设置阈值
export const bindThod = (d) => requests({ url: '/device/thod', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量设置阈值
export const bindHumth = (d) => requests({ url: '/device/humth', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除设备
export const deleteDevices = (d) => requests({ url: '/device/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除记录
export const deleteRecords = (d) => requests({ url: '/device/record/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取阈值信息
export const thInfo = (d) => requests({ url: '/device/thinfo', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取阈值信息
export const setInfo = (d) => requests({ url: '/device/setinfo', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取警报信息
export const warnCount = (d) => requests({ url: '/warn/count', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取警报信息
export const warnCount2 = (d) => requests({ url: '/warn/count2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 罗列警报信息
export const listWarn = (d) => requests({ url: '/warn/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 罗列警报信息
export const listWarn2 = (d) => requests({ url: '/warn/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 罗列警报信息
export const deleteWarn = (d) => requests({ url: '/warn/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取七牛云上传卡密
export const getQiNiuToken = (d) => requests({ url: '/qiniu/token', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询商户申请进度
export const infoApply = (d) => requests({ url: '/apply/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 提交商户申请
export const applyTrader = (d) => requests({ url: '/user/apply', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询商户申请
export const listApplys = (d) => requests({ url: '/apply/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 审核商户申请
export const examineApply = (d) => requests({ url: '/apply/examine', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除申请
export const deleteApplys = (d) => requests({ url: '/apply/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const updateHimg = (d) => requests({ url: '/user/img', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const updateNick = (d) => requests({ url: '/user/name', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const updatePass = (d) => requests({ url: '/user/pass', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const mapInfo = (d) => requests({ url: '/map/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const addMap = (d) => requests({ url: '/map/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const setPos = (d) => requests({ url: '/map/pos', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 绑定手机号
export const bindPhone = (d) => requests({ url: '/user/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改设备名称
export const updateDiviceName = (d) => requests({ url: '/device/name', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });


// 查询短信通知状态
export const smsState = (d) => requests({ url: '/device/state', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 设置短信通知状态
export const switchSms = (d) => requests({ url: '/device/switch', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });



// 获取商品列表
export const getProductList = (d) => requests({ url: '/admin/product/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品列表
export const getProductList2 = (d) => requests({ url: '/admin/product/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品详情
export const queryProduct = (d) => requests({ url: '/admin/product/query', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品详情
export const queryProduct2 = (d) => requests({ url: '/admin/product/query2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量删除商品
export const delProducts = (d) => requests({ url: '/admin/product/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 补充商品库存
export const addInventory = (d) => requests({ url: '/admin/product/addi', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProduct = (d) => requests({ url: '/admin/product/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品分类列表
export const getProductTypeList = (d) => requests({ url: '/admin/producttype/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProductType = (d) => requests({ url: '/admin/producttype/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取商品分类列表
export const getProductBrandList = (d) => requests({ url: '/admin/productbrand/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const addProductBrand = (d) => requests({ url: '/admin/productbrand/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加商品分类
export const deleteProductBrand = (d) => requests({ url: '/admin/productbrand/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 刷新卡密数据
export const reloadCdks = (d) => requests({ url: '/admin/cdk/reload', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询卡密
export const getCdkList = (d) => requests({ url: '/admin/cdk/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询卡密
export const getCdkList2 = (d) => requests({ url: '/admin/cdk/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量添加卡密
export const addProductCdks = (d) => requests({ url: '/admin/cdk/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 批量删除卡密
export const deleteCdks = (d) => requests({ url: '/admin/cdk/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询卡密信息
export const getCdkInfo = (d) => requests({ url: '/cdk/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 查询卡密详情
export const getOrderDetail = (d) => requests({ url: '/shop/detail', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 卡密兑换
export const exchangeProduct = (d) => requests({ url: '/shop/exchange', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取系统公告
export const noticeList = (d) => requests({ url: '/admin/sys/notice/all', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 获取卡密兑换订单
export const getExOrders = (d) => requests({ url: '/admin/exorder/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 删除兑换订单
export const delExOrders = (d) => requests({ url: '/admin/exorder/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询地址信息
export const getAreaInfo = (d) => requests({ url: '/admin/area/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 审核订单信息
export const examineOrder = (d) => requests({ url: '/admin/exorder/examine', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 订单发货申请
export const faHuo = (d) => requests({ url: '/admin/exorder/fahuo', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 搜索订单
export const searchOrders = (d) => requests({ url: '/admin/exorder/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 搜索订单
export const searchOrders2 = (d) => requests({ url: '/admin/exorder/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 分页查询消息
export const getMyMess = (d) => requests({ url: '/admin/mess/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 财务入库页面产品分级列表
export const proList = (d) => requests({ url: '/admin/product/ware', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库页面产品分级列表
export const proList2 = (d) => requests({ url: '/admin/product/ware2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const wareList = (d) => requests({ url: '/admin/finance/warehouse/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const outList = (d) => requests({ url: '/admin/finance/warehouse/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const wareList2 = (d) => requests({ url: '/admin/finance/warehouse/list3', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 财务入库查询
export const outList2 = (d) => requests({ url: '/admin/finance/warehouse/list4', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加通知
export const addNotice = (d) => requests({ url: '/admin/sys/notice/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除通知
export const deleteNotices = (d) => requests({ url: 'admin/sys/notice/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const listNotices = (d) => requests({ url: 'admin/sys/notice/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const changeNotice = (d) => requests({ url: 'admin/sys/notice/change', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工领用卡券
export const cardVoucher = (d) => requests({ url: '/admin/finance/cdkuse/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 管理端信息查询
export const adminInfo = (d) => requests({ url: '/admin/info/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 抽奖查询
export const listLottery = (d) => requests({ url: '/admin/product/lottery/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加抽奖
export const addLottery = (d) => requests({ url: '/admin/product/lottery/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 抽奖查询
export const listLottery2 = (d) => requests({ url: '/shop/lottery/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d) });
// 添加轮播
export const addLunbo = (d) => requests({ url: '/admin/sys/lunbo/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除轮播
export const deleteLunbos = (d) => requests({ url: '/admin/sys/lunbo/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 修改轮播
export const updateLunbo = (d) => requests({ url: '/admin/sys/lunbo/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询轮播
export const listLunbo = (d) => requests({ url: '/admin/sys/lunbo/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 主页查询轮播
export const listLunbo2 = (d) => requests({ url: '/sys/lunbo/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 获取商户信息
export const getFirmInfo = (d) => requests({ url: '/firm/info/get', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 更新商户信息
export const updateFirmInfo = (d) => requests({ url: '/firm/info/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询通知列表
export const userInfos = (d) => requests({ url: '/user/info', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const searchRoles = (d) => requests({ url: '/role/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const addRole = (d) => requests({ url: '/role/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const searchRoles2 = (d) => requests({ url: '/role/search', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const updateRole = (d) => requests({ url: '/role/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询商户申请进度
export const deleteRoles = (d) => requests({ url: '/role/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 添加菜单
export const addMenu = (d) => requests({ url: '/menu/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询菜单2
export const listMenu2 = (d) => requests({ url: '/menu/list2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 删除菜单
export const deleteMenu = (d) => requests({ url: '/menu/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 角色绑定权限
export const bindRam = (d) => requests({ url: '/ram/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工角色权限
export const bindUar = (d) => requests({ url: '/uar/add', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除员工角色绑定
export const deleteUars = (d) => requests({ url: '/uar/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 员工角色权限
export const updateUar = (d) => requests({ url: '/uar/update', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 查询日志
export const listLogs = (d) => requests({ url: '/admin/log/list', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 绑定邮箱
export const bindEmail = (d) => requests({ url: '/admin/email/bind', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 绑定邮箱2
export const bindEmail2 = (d) => requests({ url: '/admin/email/bind2', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const deleteUsers = (d) => requests({ url: '/admin/user/delete', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
// 批量删除用户
export const checkLogin = (d) => requests({ url: '/wx/check', method: 'post', headers: { 'x-codic': getCookie('x-myweb-key'), 'x-mytk': JSON.parse(getCookie('user-inf'))?.token, 'x-sid': JSON.parse(getCookie('user-inf'))?.user?.uar?.sid, 'Content-Type': 'application/x-www-form-urlencoded' }, data: Qs.stringify(d, { indices: false }) });
