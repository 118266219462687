<template>
    <div class="codics-factory-admin-device-body">
        <div class="codics-factory-admin-device-control">

            <!-- <el-dropdown class="codics-factory-admin-device-control-search" split-button type="primary"
                @click="handleBindClick" @command="handleThodClick">
                <i class="iconfont" style="font-size: 13px;">&#xe744; </i>批量绑定
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>批量设置阈值</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown> -->
            <el-button class="codics-factory-admin-device-control-search iconfont" @click="handleThodClick"
                type="success" plain>&#xe744; 批量设置阈值</el-button>
            <el-popconfirm :title="'您确定要解绑当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteDevicesFuc">
                <el-button class="codics-factory-admin-device-control-search" slot="reference" type="danger"
                    icon="el-icon-delete" plain>批量解绑</el-button>
            </el-popconfirm>
            <el-input class="codics-factory-admin-device-control-search"
                style="width: 300px !important;float: right !important;margin-right: 0 !important;"
                placeholder="请输入账号/昵称/邮箱/手机号..." v-model="searchTxt" clearable @input="searchChange">
                <el-button style="padding: 0 15px 0 15px;" slot="append" icon="el-icon-search"
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listDeviceFuc();"></el-button>
            </el-input>
            <el-dialog title="绑定设备" :visible.sync="bindVisible" width="80%" :close-on-click-modal='false'>
                <el-select v-model="selectUserData" style="width: 100%;margin-bottom: 10px;" filterable remote
                    reserve-keyword placeholder="请输入用户账号/昵称/邮箱/手机号" :remote-method="searchUsersFuc"
                    :loading="userLoading" clearable>
                    <el-option v-for="(item, index) in usersOptions" :key="index" :label="item.nickname"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-tag v-for="tag in selectData" :key="tag.imei" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.imei }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindDevicesFuc(selectUserData)">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="设置温度阈值" :visible.sync="thodVisible" width="80%" :close-on-click-modal='false'>
                <el-form :model="form">
                    <el-form-item label="温度阈值" :label-width="formLabelWidth">
                        <el-input-number v-model="thodNum" :precision="2" :step="0.1" :max="100"
                            style="margin-right: 90%;margin-bottom: 15px;"></el-input-number>
                    </el-form-item>
                </el-form>
                <el-tag v-for="tag in selectData" :key="tag.imei" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.imei }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="bindVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindThodFuc(thodNum)">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="修改设备名称" :visible.sync="nameVisible" width="80%" :close-on-click-modal='false'>
                <el-form >
                    <el-form-item label="设备名称" :label-width="formLabelWidth">
                        <el-input v-model="deviceName" maxlength="20" placeholder="请输入设备名称"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="nameVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updateDiviceNameFuc(nowDevice)">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="设置湿度阈值" :visible.sync="humVisible" width="80%" :close-on-click-modal='false'>
                <el-form :model="form">
                    <el-form-item label="湿度阈值" :label-width="formLabelWidth">
                        <el-input-number v-model="humNum" :precision="2" :step="0.1" :max="100"
                            style="margin-right: 90%;margin-bottom: 15px;"></el-input-number>
                    </el-form-item>
                </el-form>
                <el-tag v-for="tag in selectData" :key="tag.imei" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.imei }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="humVisible = false">取 消</el-button>
                    <el-button type="primary" @click="bindHumthFuc(humNum)">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <el-table stripe class="codics-factory-admin-device-table" :data="searchTxt == '' ? deviceList : searchData"
            border style="width: 100%" @selection-change="handleSelectionChange" ref="userTable"
            @expand-change="init_chart">
            <el-table-column type="expand" style="padding: 25px;">
                <template slot-scope="props">
                    <div :id="'eChartDevice' + props.row.id" class="codics-factory-admin-device-echarts"></div>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="43">
            </el-table-column>
            <el-table-column prop="id" label="主键" width="70">
            </el-table-column>
            <el-table-column prop="pcode" label="设备码" width="180" style="user-select: none;">
            </el-table-column>
            <el-table-column prop="imei" label="物联网编号" width="180">
            </el-table-column>
            <el-table-column prop="icode" label="类型" width="70"
                :filters="[{ text: '温度', value: '22' }, { text: '湿度', value: '23' }]" :filter-method="icodeFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.icode == 22 ? 'success' : 'danger'" disable-transitions>{{ scope.row.icode
                        ==
                        22 ? '温度' : '湿度' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="temp" label="温度(℃)" width="80">
            </el-table-column>
            <el-table-column prop="hum" label="湿度(RH)" width="80">
            </el-table-column>
            <el-table-column prop="himg" label="信号" width="50">
                <template slot-scope="scope">
                    <svg class="icon codics-factory-admin-device-signal" aria-hidden="true">
                        <use
                            :xlink:href="scope.row.sgal > 20 ? '#icon-xinhao' : (scope.row.sgal > 15 ? '#icon-xinhao-copy2' : (scope.row.sgal > 10 ? '#icon-xinhao-copy' : (scope.row.sgal > 5 ? '#icon-xinhao-copy-copy' : '#icon-xinhao-wu')))">
                        </use>
                    </svg>
                </template>
            </el-table-column>
            <el-table-column prop="himg" label="电量" width="50">
                <template slot-scope="scope">
                    <svg class="icon codics-factory-admin-device-signal" aria-hidden="true">
                        <use
                            :xlink:href="scope.row.voge > 3.8 ? '#icon-iconset0252' : (scope.row.voge > 3.6 ? '#icon-iconset0251' : (scope.row.voge > 3.4 ? '#icon-iconset0250' : (scope.row.voge > 3.2 ? '#icon-iconset0249' : '#icon-iconset0248')))">
                        </use>
                    </svg>
                </template>
            </el-table-column>
            <el-table-column prop="thod" label="温度阈值(℃)" width="140">
                <template slot-scope="scope">
                    {{ scope.row.thod == null ? '未设置' : scope.row.thod }}
                    <el-button size="mini" type="info" slot="reference" style="margin-left: 8px;"
                        @click="selectData = [scope.row]; thodNum = scope.row.thod; humNum = scope.row.humth; thodVisible = true;"
                        plain>{{
                        scope.row.thod == null ? '设置' :
                        '修改' }}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="humth" label="湿度阈值(℃)" width="140">
                <template slot-scope="scope">
                    {{ scope.row.humth == null ? '未设置' : scope.row.humth }}
                    <el-button size="mini" type="info" slot="reference" style="margin-left: 8px;"
                        @click="selectData = [scope.row]; humNum = scope.row.humth; humNum = scope.row.humth; humVisible = true;"
                        plain>{{
                        scope.row.humth == null ? '设置' :
                        '修改' }}</el-button>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini"
                        @click="nameVisible = true; deviceName = scope.row.pcode; nowDevice = scope.row;"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="您确定要解绑当前设备吗？" @confirm="deleteDevicesFuc([scope.row.id])">
                        <el-button v-show="scope.row.children == null" size="mini" type="danger" slot="reference"
                            plain>解绑</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-factory-admin-device-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import { listDevice2, bindDevices, searchUsers, bindThod, deleteDevices, bindHumth, updateDiviceName } from '@/api';
export default {
    data() {
        return {
            nameVisible:false,
            bindVisible: false,
            thodVisible: false,
            humVisible: false,
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '80px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            deviceList: [],
            selectData: [],
            usersOptions: [],
            userLoading: false,
            selectUserData: '',
            thodNum: 0,
            humNum: 0,
            nowSearchTxt: '',
            deviceName: '',
            nowDevice:null
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listDeviceFuc();

    },
    methods: {
        async updateDiviceNameFuc(row) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await updateDiviceName({ id: row.id, name: this.deviceName });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '设备名称更新成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleBindClick() {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.bindVisible = true;

        },
        async deleteDevicesFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要解绑的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteDevices({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据解绑成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleThodClick(c) {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的设备！',
                    type: 'warning'
                });

                return;

            }

            this.thodVisible = true;

        },
        icodeFilter(v, r, c) {

            console.log(v, r.icode)

            return r.icode === v;

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        async bindDevicesFuc(sid, ids) {

            if (sid == null || sid == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中要绑定的商户数据！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindDevices({ sid: sid, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindThodFuc(thod, ids) {

            if (thod == null || thod == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的温度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindThod({ thod: thod, ids: ids });

            this.bindVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async bindHumthFuc(humth, ids) {

            if (humth == null || humth == '') {

                this.$message({
                    showClose: true,
                    message: '请先设置要设定的湿度！',
                    type: 'warning'
                });

                return;

            }

            if (ids == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要重置的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindHumth({ humth: humth, ids: ids });

            this.humVisible = false;

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '数据绑定成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        init_chart(data, list) {

            if (list.includes(data)) {

                setTimeout(() => {

                    let rList = [...data.recordList];

                    rList.reverse();

                    let n1 = new Date().getDay();

                    let t = rList.map(function (item, index) {
                        return item?.updatetime; // 返回值可以用于创建新数组
                    });

                    t = t.map((n) => {

                        let date = new Date(n);

                        let r = n1 == date.getDay() ? '' : '昨日 ';

                        let hour = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());

                        let menute = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());

                        let second = date.getSeconds() >= 10 ? date.getSeconds() : ('0' + date.getSeconds());

                        return `${r}${hour}:${menute}:${second}`;

                    });

                    t[t.length - 1] = '现在';

                    let d = rList.map(function (item, index) {
                        return item?.temp; // 返回值可以用于创建新数组
                    });

                    let d2 = rList.map(function (item, index) {
                        return item?.hum; // 返回值可以用于创建新数组
                    });

                    let chartDom = document.getElementById('eChartDevice' + data.id);

                    this.myChart = echarts.init(chartDom);

                    var option;

                    option = {
                        title: {
                            text: '',
                            show: false
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            y: 'bottom',
                            left: 'center'
                        },
                        toolbox: {
                            show: true,
                            top: 10,
                            right: 10,
                            itemSize: 11.5,
                            feature: {
                                saveAsImage: {}
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: true,
                                data: t
                            },
                            {
                                type: 'category',
                                boundaryGap: true,
                                data: t
                            }
                        ],
                        //     {
                        //     type: 'category',
                        //         boundaryGap: false,
                        //             data: t.reverse()
                        // }
                        yAxis: [
                            {
                                type: 'value',
                                scale: true,
                                name: '温度',
                                boundaryGap: [0.2, 0.2],
                                axisLabel: {
                                    formatter: '{value} °C'
                                }
                            },
                            {
                                type: 'value',
                                scale: true,
                                name: '湿度',
                                formatter: '{value} %RH',
                                boundaryGap: [0.2, 0.2]
                            }
                        ],
                        // {
                        //     type: 'value',
                        //     axisLabel: {
                        //         formatter: '{value} °C'
                        //     }
                        // },
                        grid: {
                            top: 50
                        },
                        series: [
                            {
                                name: '仓库温度',
                                type: 'line',
                                smooth: true,
                                data: d,
                                xAxisIndex: 0,
                                yAxisIndex: 0,
                                markPoint: {
                                    data: [
                                        { type: 'max', name: 'Max' },
                                        { type: 'min', name: 'Min' }
                                    ],
                                    // itemStyle: {
                                    //     normal: {
                                    //         borderColor: 'red',
                                    //         borderWidth: 2
                                    //     },
                                    //     emphasis: {
                                    //         borderColor: 'blue',
                                    //         borderWidth: 3
                                    //     }
                                    // }
                                },
                                markLine: {
                                    data: [{ type: 'average', name: 'Avg' }]
                                },
                                // lineStyle: {
                                //     normal: {
                                //         color: function (params) {
                                //             if (params.value > 28) {
                                //                 return '#FF0000'; // 超过特定值后的线条颜色
                                //             } else {
                                //                 return '#5c7bd9'; // 默认颜色
                                //             }
                                //         },
                                //     },
                                //     emphasis: {
                                //         color: function (params) {
                                //             if (params.value > 28) {
                                //                 return '#FF0000'; // 超过特定值后的线条颜色
                                //             } else {
                                //                 return '#5c7bd9'; // 默认颜色
                                //             }
                                //         }
                                //     }
                                // },
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            // 自定义判断逻辑，如果数据大于特定值则改变颜色
                                            if (params.value > 30) {
                                                return '#FF0000'; // 超过特定值后的点颜色
                                            } else {
                                                return '#5c7bd9'; // 默认颜色
                                            }
                                        }
                                    }
                                }
                                // },
                                // lineStyle: {
                                //     normal: {
                                //         color: function (params) {
                                //             if (params.value > 28) {
                                //                 return '#FF0000'; // 超过特定值后的线条颜色
                                //             } else {
                                //                 return '#5c7bd9'; // 默认颜色
                                //             }
                                //         }
                                //     }
                                // }
                            },
                            {
                                name: '仓库湿度',
                                type: 'bar',
                                smooth: true,
                                data: d2,
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                markPoint: {
                                    data: [{ name: '周最低', value: -2, xAxis: 1, yAxis: -1.5 }]
                                },
                                markLine: {
                                    data: [
                                        { type: 'average', name: 'Avg' },
                                        [
                                            {
                                                symbol: 'none',
                                                x: '90%',
                                                yAxis: 'max'
                                            },
                                            {
                                                symbol: 'circle',
                                                label: {
                                                    position: 'start',
                                                    formatter: 'Max'
                                                },
                                                type: 'max',
                                                name: '最高点'
                                            }
                                        ]
                                    ]
                                }
                            }
                        ]
                    };

                    option && this.myChart.setOption(option);

                }, 30);

            }

        },
        handleUserTagClose(tag) {
            this.$refs.userTable.selection.splice(this.$refs.userTable.selection.indexOf(tag), 1);
            this.selectData = this.$refs.userTable.selection;
            if (this.selectData.length == 0) {
                this.bindVisible = false;
                this.thodVisible = false;
            }
        },
        async listDeviceFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listDevice2({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.nowSearchTxt == '') {

                    this.deviceList = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
            } else {
                this.searchSize = val;
            }
            this.listDeviceFuc();
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
            } else {
                this.searchPage = val;
            }
            this.listDeviceFuc();
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductBrand(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-factory-admin-device-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-factory-admin-device-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-factory-admin-device-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-factory-admin-device-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-factory-admin-device-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-factory-admin-device-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-factory-admin-device-signal {
    height: 16px !important;
    width: 16px !important;
}

.codics-factory-admin-device-echarts {
    height: 300px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}
</style>