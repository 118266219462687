<template>
    <div style="height: calc(100% - 60px);background: #FFF;">
        <div class="codics-factory-user-body">
            <div class="codics-factory-user-himg-box">
                <div class="codics-factory-user-himg">
                    <el-upload class="codics-factory-user-himg-img-upload" action="cdn.bosscaigou.com" accept="image/*"
                        :http-request="uploadFile" :limit="1" :show-file-list="false">
                        <el-image class="codics-factory-user-himg-img" type="primary" style="border-radius: 10px;"
                            shape="square" :size="150"
                            :src="userInfo.user.himg != null ? ('https://cdn.bosscaigou.com/' + userInfo.user.himg) : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-image>
                    </el-upload>
                </div>
            </div>
            <el-input class="codics-factory-user-info-input" style="margin-bottom: 20px;" :maxlength="30" clearable
                placeholder="请输入公司名称" v-model="nickName" :disabled="nickState">
                <el-button v-show="nickState" slot="append" icon="el-icon-edit" @click="nickState = false;"></el-button>
                <el-button v-show="!nickState" style="margin: 0 0 0 0 !important;" slot="append" icon="el-icon-check"
                    @click="updateNickFuc();"></el-button>
                <el-button v-show="!nickState" style="margin: 0 0 0 0 !important;" slot="append" icon="el-icon-close"
                    @click="nickState = true;"></el-button>
            </el-input>
            <div class="codics-factory-user-info-xgmm-box">
                <el-button class="codics-factory-user-info-xgmm" type="warning" style="width: 100%;" plain
                    @click="passVisible = true;">修改密码</el-button>
            </div>
        </div>
        <el-dialog title="修改密码" :close-on-click-modal='false' :visible.sync="passVisible" width="80%">
            <el-form :model="updateForm">
                <el-form-item label="原密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.o" autocomplete="off" placeholder="请输入原密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
                <el-form-item label="重复原密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.opass1" autocomplete="off" placeholder="请输入原密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
                <el-form-item label="现密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.n" autocomplete="off" placeholder="请输入现密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="passVisible = false">取 消</el-button>
                <el-button type="primary" @click="updatePassFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { randomString, jumpTo } from '@/utils/myutils';
import * as qiniu from 'qiniu-js';
import { mapState } from 'vuex';
import { applyTrader, updateHimg, updateNick, updatePass, getQiNiuToken } from '@/api';
export default {
    data() {
        return {
            formLabelWidth: '110px',
            nickName: '',
            nickState: true,
            emailState: true,
            phoneState: true,
            sjsqVisible: false,
            passVisible: false,
            qiNiuInfo: {
                token: null
            },
            updateForm: {
                o: '',
                opass1: '',
                n: ''
            },
            userImg: ''
        }
    },
    mounted() {
        this.$emit('check-mem');
        this.nickName = this.userInfo.user.nickname;
        // this.userImg = (this.userInfo?.user?.himg == null || this.userInfo?.user?.himg == '') ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : ('https://cdn.bosscaigou.com/' + this.userInfo?.user?.himg);
        this.getTokenFuc();
    },
    methods: {
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        async applyTraderFuc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await applyTrader();
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '商户申请提交成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async updateHimgFuc(img) {
            if (img == '' || img == null) {
                this.$message({
                    message: '头像不能为空！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await updateHimg({ 'img': img });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '更新头像成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async updateNickFuc() {
            if (this.nickName == '') {
                this.$message({
                    message: '昵称不能为空！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await updateNick({ 'name': this.nickName });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '更新昵称成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
            this.nickState = false;
        },
        async updatePassFuc() {
            if (this.updateForm.o == '' || this.updateForm.opass1 == '' || this.updateForm.n == '') {
                this.$message({
                    message: '密码不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.updateForm.o != this.updateForm.opass1) {
                this.$message({
                    message: '两次输入的密码不一致！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await updatePass(this.updateForm);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '更新密码成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    this.$store.dispatch("logout");

                    jumpTo(this, "工厂首页", { url: "admin" }, {});
                }, 300);
            } else {
                this.$message.error(data.message);
            }
            this.passVisible = false;
        },
        uploadFile(uploadInfo) {

            let imgPath = 'huayuan/img/' + randomString(10) + '_' + new Date().getTime() + '_' + this.userInfo?.user?.id + '.png';

            const _that = this;
            // 上传文件
            const observer2 = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {

                    _that.updateHimgFuc(res.key);

                }
            }

            const observable2 = qiniu.upload(uploadInfo.file, imgPath, this.qiNiuInfo.token, {}, {
            })

            const subscription2 = observable2.subscribe(observer2) // 上传开始

        },
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        userInfo(newVal, oldVal) {
            this.nickName = newVal.user.nickname;
        }
    }
}
</script>

<style>
/* 个人中心页面体 */
.codics-factory-user-body {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 头像盒子容器 */
.codics-factory-user-himg-box {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

.codics-factory-user-himg-img-upload .el-upload {
    display: block !important;
}

/* 个人中心用户头像盒子 */
.codics-factory-user-himg {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 5px #e0dede;
    cursor: pointer;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 40px;
}

/* 个人中心用户头像 */
.codics-factory-user-himg-img {
    height: 150px !important;
    width: 150px !important;
    position: relative !important;
    box-sizing: border-box;
    display: block !important;
}

.codics-factory-user-shop-shen-qing::before {
    content: '';
    height: 160px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #FFF;
    border-radius: 10px;
}

/* 个人中心用户头像盒子添加按钮阴影遮罩 */
.codics-factory-user-add {
    height: 160px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .45);
    opacity: 0;
    transition: .4s;
    z-index: 2;
    border-radius: 10px;
}

/* 用户头像盒子Hover事件 */
.codics-factory-user-himg:hover .codics-factory-user-add {
    opacity: 1;
}

.codics-factory-user-himg:hover .codics-factory-user-shop-shen-qing {
    height: 200px;
}

/* 用户头像盒子编辑图标 */
.codics-factory-user-add-icon {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    color: #FFF;
}

/* 用户头像申请成为商户按钮 */
.codics-factory-user-shop-shen-qing {
    height: 160px;
    width: 160px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    background: #F5F7FA;
    z-index: 0;
    border-radius: 10px;
    transition: .4s;
}

/* 用户信息输入框 */
.codics-factory-user-info-input {
    width: 95%;
    max-width: 700px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

/* 用户其他信息输入盒子 */
.codics-factory-user-info-box {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 用户其他信息输入容器 */
.codics-factory-user-info-card {
    height: fit-content;
    width: 100%;
    max-width: 700px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

/* 修改密码按钮 */
.codics-factory-user-info-xgmm {
    position: relative;
    float: left;
    max-width: 700px;
    left: 50%;
    transform: translateX(-50%);
}

.codics-factory-user-info-xgmm-box {
    width: 100%;
    position: relative;
    float: left;
}

@media (max-width:768px) {

    .codics-factory-user-info-card-input {
        width: 100% !important;
        margin-bottom: 20px;
        margin-right: 0 !important;
    }

    .codics-factory-user-info-input {
        width: 100% !important;
    }

    .codics-factory-user-info-box {
        margin-bottom: unset !important;
    }

}
</style>