<template>
    <div class="codics-factory-login-body">
        <div class="codics-factory-login-box">
            <div class="codics-factory-login-carousel-box">
                <el-carousel class="codics-factory-login-carousel" height="362px" width="100%">
                    <el-carousel-item class="codics-factory-login-carousel-item" v-for="item in 1" :key="item">
                        <el-image src="https://cdn.bosscaigou.com/huayuan/img/banner222.png"
                            style="height: 100%;width: 100%;" fit="fill"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="codics-factory-login-view">
                <div class="codics-factory-login-title-box">
                    <div class="codics-factory-login-title" v-for="(item, index) in ['密码登录', '扫码登录']" :key="index"
                        :style="{ '--idxState': idxState == index ? '2px solid #333' : 'unset', '--fontCol': idxState == index ? '#333' : '#888' }"
                        @click="idxState = index">{{ item }}</div>
                </div>
                <el-image v-if="idxState == 1" class="codics-factory-login-qrcode" :src="qrImg" fit="fill"></el-image>
                <div v-show="idxState == 1" class="codics-factory-login-qrcode-tip" v-html="qrTxt">
                </div>
                <el-input v-show="idxState == 0" v-model="logInfo.a" maxlength="22" placeholder="请输入账号/邮箱/手机号..."
                    style="margin-bottom: 15px;" clearable></el-input>
                <el-input v-show="idxState == 0" v-model="logInfo.p" maxlength="22" placeholder="请输入账号相应的密码..."
                    :show-password="true" style="margin-bottom: 15px;" clearable></el-input>
                <el-input v-show="idxState == 0" class="codics-factory-login-verify-code" placeholder="请输入验证码"
                    maxlength="4" v-model="logInfo.v" clearable>
                    <template slot="append">
                        <el-image class="codics-factory-login-input-card-code" :src="verifyImg" fit="fill"
                            @click="changeVerifyFuc">
                            <div slot="error" class="image-slot"
                                style="height:fit-content;width: fit-content;position: absolute;margin: auto;top: 0;left: 0;right: 0;bottom: 0;"
                                @click="changeVerifyFuc">
                                <i class="el-icon-loading" v-show="verifyState"></i>
                            </div>
                        </el-image>
                        <div style="height: 100%;width: 100%;position: absolute;line-height: 40px;text-align: center;font-size: 13px;left: 0;top: 0;user-select: none;cursor: pointer;"
                            v-show="!verifyState" @click="changeVerifyFuc(); verifyState = true;">加载验证码</div>
                    </template>
                </el-input>
                <div v-show="idxState == 0" class="codics-factory-login-line">
                    <el-checkbox v-model="logCheck">7天免登录</el-checkbox>
                    <a class="codics-factory-login-line-pass" href="https://qm.qq.com/q/VPQBQGwUUI">忘记密码？</a>
                </div>
                <el-button v-show="idxState == 0" class="codics-factory-login-input-card-login" plain type="warning"
                    @click="userLoginFuc">登录/注册</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { changeVerify, userLogin, checkLogin } from '@/api';
import { setCookieWithExp, getCookie } from '@/utils/mycookie';
import { jumpTo } from '@/utils/myutils';
import QRCode from 'qrcode';
export default {
    data() {
        return {
            idxState: 0,
            logInfo: {
                a: '',
                p: '',
                v: '',
                e: 0
            },
            logCheck: false,
            verifyImg: '',
            verifyState: false,
            qrImg: '',
            lunboData: [
                {
                    img: 'https://cdn.bosscaigou.com/huayuan/img/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240708170421.png'
                }
            ],
            nowTimer: null,
            qrTxt:'请使用<a href="#">微信App</a>扫码登录,点击确认登录'
        }
    },
    mounted() {
        this.changeVerifyFuc();
        this.initQrcode();
        this.nowTimer = setInterval(() => {
            this.nowTimer && this.checkLoginFuc();
        }, 3000);
    },
    beforeDestroy() {
        this.nowTimer != null && clearInterval(this.nowTimer);
    },
    methods: {
        async checkLoginFuc() {
            let data = await checkLogin();
            if (data.code == 200) {

                if (data.state == 0) {

                    this.qrTxt = '请使用<a href="#">微信App</a>扫码登录,点击确认登录';

                } else if (data.state == 1) {

                    this.qrTxt = '扫码成功，正在等待微信端扫码确认完成登录！'

                } else if (data.state == 2) {

                    this.nowTimer != null && clearInterval(this.nowTimer);

                    this.nowTimer = null;

                    this.qrTxt = '登录成功，等待跳转至微信页面！';

                    this.$message({
                        message: data.message,
                        type: 'success'
                    });
                    setCookieWithExp("user-inf", JSON.stringify(data), this.logCheck ? 7 : (1 / 12));
                    // 刷新仓库数据
                    this.$store.dispatch('login');
                    // 登录成功跳转
                    console.log(data.user.member == 0 ? '数据主页' : (this, data.user.uar != null ? '商户数据主页' : '商户申请'))
                    jumpTo(this, data.user.member == 0 ? '数据主页' : (this, data.user.uar != null ? '商户数据主页' : '商户申请'), {}, {});

                }

            } else {
                this.$message.error(data.message);
            }
        },
        async initQrcode() {

            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 1,//二维码留白边距
                width: 100,//宽
                height: 100,//高
                text: 'https://factory.katihuo.com/api/wx/jumpl?pkey=' + getCookie('x-myweb-key'),//二维码内容
                color: {
                    dark: "#000",//前景色
                    light: "#fff"//背景色
                }
            };
            await QRCode.toDataURL(opts.text, opts, (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                // console.log(url)
                this.qrImg = url;
            });

        },
        async changeVerifyFuc() {
            let data = await changeVerify();
            if (data.code == 200) {
                this.verifyState = true;
                this.verifyImg = data.data;
            } else {
                this.$message.error(data.message);
            }
        },
        async userLoginFuc() {
            if (this.logInfo.a == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的账号不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.logInfo.p == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的密码不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.logInfo.v == '') {
                this.$notify({
                    title: '警告',
                    message: '填写的验证码不能为空！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.logInfo.e = this.logCheck ? 168 : 2;
            let data = await userLogin(this.logInfo);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.message,
                    type: 'success'
                });
                setCookieWithExp("user-inf", JSON.stringify(data), this.logCheck ? 7 : (1 / 12));
                // 刷新仓库数据
                this.$store.dispatch('login');
                // 登录成功跳转
                console.log(data.user.member == 0 ? '数据主页' : (this, data.user.uar != null ? '商户数据主页' : '商户申请'))
                jumpTo(this, data.user.member == 0 ? '数据主页' : (this, data.user.uar != null ? '商户数据主页' : '商户申请'), {}, {});
            } else this.$message.error(data.message);
        }
    },
    watch: {
        idxState(newVal, oldVal) {
            
        }
    }
}
</script>
<style>
/* 登录界面 */
.codics-factory-login-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    /* background: #EBECF0; */
    background-image: url('../../../public/img/yasuo_bck.webp');
    background-size: 290px 290px;
    /* 替换为你的图片路径 */
    background-repeat: repeat;
    /* 让背景图片平铺 */
}

/* 登录输入框容器 */
.codics-factory-login-box {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
}

/* 轮播盒子 */
.codics-factory-login-carousel-box {
    height: 362px;
    width: 650px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0px 0;
}

.codics-factory-login-carousel {
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 0 6px 2px rgba(0, 0, 0, .08);
}

.codics-factory-login-carousel-item {
    height: 100%;
    width: 100%;
    background: #333;
}

.codics-factory-login-view {
    height: 362px;
    width: 380px;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .08);
    padding: 25px;
}

.codics-factory-login-title-box {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
}

.codics-factory-login-title {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
    color: var(--fontCol);
    font-size: 14px;
    font-weight: 500;
    border-bottom: var(--idxState);
    cursor: pointer;
    user-select: none;
}

/* 验证码图片 */
.codics-factory-login-input-card-code {
    height: 25px;
    width: 62.5px;
    top: 2.5px;
    box-sizing: border-box;
    cursor: pointer;
    color: #333;
    font-size: 11px;
}

.codics-factory-login-verify-code .el-input-group__append {
    padding: 0 10px;
}

.codics-factory-login-input-card-login {
    width: 100%;
}

input:-webkit-autofill {
    transition: background-color 10000s ease-in-out 0s !important;
}

.codics-factory-login-line {
    height: 55px;
    width: 100%;
    position: relative;
    float: left;
    /* margin-bottom: 10px; */
    line-height: 55px;
}

.codics-factory-login-line-pass {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    line-height: 55px;
    font-size: 13px;
    color: #333;
    text-decoration: none;
}

@media (max-width:1030px) {
    .codics-factory-login-carousel-box {
        display: none;
    }
}

.codics-factory-login-qrcode {
    height: 120px;
    width: 120px;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-factory-login-qrcode-tip {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 65%;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #333;
}
</style>