import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home';
import AdminLogin from '@/pages/Login';
import Admin from '@/pages/Admin';
import Apply from '@/pages/Apply';
import AdminHome from '@/pages/Admin/Home';
import AdminHome2 from '@/pages/Admin/Home2';
import AdminUser from '@/pages/Admin/User';
import AdminUserApply from '@/pages/Admin/User/Apply';
import AdminUserStaff from '@/pages/Admin/User/Staff';
import AdminUserMenu from '@/pages/Admin/User/Menu';
import AdminDevice from '@/pages/Admin/Device';
import AdminDeviceRecord from '@/pages/Admin/Device/Record';
import AdminWarn from '@/pages/Admin/Warn';
import AdminFactory from '@/pages/Admin/Factory';
import AdminInfo from '@/pages/Admin/Info';
import Firm from '@/pages/Firm';
import FirmHome from '@/pages/Firm/Home2';
import FirmFactory from '@/pages/Firm/Factory';
import FirmStaff from '@/pages/Firm/User/Staff';
import FirmMenu from '@/pages/Firm/User/Menu';
import FirmDevice from '@/pages/Firm/Device';
import FirmRecord from '@/pages/Firm/Device/Record';
import FirmWarn from '@/pages/Firm/Warn';
import FirmInfo from '@/pages/Firm/Info';
import FirmRole from '@/pages/Firm/User/Role';
Vue.use(VueRouter);
export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: '工厂首页',
            path: '/',
            component: AdminLogin,
            meta: {
                metaInfo: {
                    title: '熊邦邦温湿度管家-工厂首页',
                    keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                    description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                }
            }
        },
        {
            name: '后台登录',
            path: '/login',
            component: AdminLogin,
            meta: {
                metaInfo: {
                    title: '熊邦邦温湿度管家-后台登录',
                    keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                    description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                }
            }
        },
        {
            name: '工厂后台',
            path: '/admin',
            component: Admin,
            meta: {
            },
            children: [
                {
                    name: '数据主页',
                    path: 'home',
                    component: AdminHome2,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-数据主页',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '数据主页2',
                    path: 'home2',
                    component: AdminHome,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-数据主页',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '工厂管理',
                    path: 'factory',
                    component: AdminFactory,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-工厂管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '用户管理',
                    path: 'user',
                    component: AdminUser,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-用户管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '员工管理',
                    path: 'staff',
                    component: AdminUserStaff,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-员工管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '菜单管理',
                    path: 'menu',
                    component: AdminUserMenu,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-菜单管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '设备管理',
                    path: 'device',
                    component: AdminDevice,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-设备管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '设备记录',
                    path: 'record',
                    component: AdminDeviceRecord,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-设备记录',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户管理',
                    path: 'apply',
                    component: AdminUserApply,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '预警记录',
                    path: 'warn',
                    component: AdminWarn,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-预警记录',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '个人信息',
                    path: 'info',
                    component: AdminInfo,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-个人信息',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    path: '/admin*',
                    redirect: '/admin/home'
                }
            ]
        },
        {
            name: '商户后台',
            path: '/firm',
            component: Firm,
            meta: {
            },
            children: [
                {
                    name: '商户数据主页',
                    path: 'home',
                    component: FirmHome,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户数据主页',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户工厂管理',
                    path: 'factory',
                    component: FirmFactory,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户工厂管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户角色管理',
                    path: 'role',
                    component: FirmRole,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户角色管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户员工管理',
                    path: 'staff',
                    component: FirmStaff,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户员工管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户菜单管理',
                    path: 'menu',
                    component: FirmMenu,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户菜单管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户设备管理',
                    path: 'device',
                    component: FirmDevice,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户设备管理',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户设备记录',
                    path: 'record',
                    component: FirmRecord,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户设备记录',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户预警记录',
                    path: 'warn',
                    component: FirmWarn,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户预警记录',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    name: '商户个人信息',
                    path: 'info',
                    component: FirmInfo,
                    meta: {
                        metaInfo: {
                            title: '熊邦邦温湿度管家-商户个人信息',
                            keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                            description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                        }
                    }
                },
                {
                    path: '/firm*',
                    redirect: '/firm/home'
                }
            ]
        },
        {
            name: '商户申请',
            path: '/apply',
            component: Apply,
            meta: {
                metaInfo: {
                    title: '熊邦邦温湿度管家-商户申请',
                    keywords: '熊邦邦温湿度管家，温度监测，湿度监测，短信预警',
                    description: '熊邦邦温湿度管家，您工厂冷库的智能守护者！专为现代化冷链管理而设计，集高精度监测与即时预警功能于一体，确保您的产品始终处于最佳储存环境。'
                }
            }
        },
        {
            path: '*',
            redirect: '/'
        },
    ],
});